import React, { useEffect } from 'react';
import Navbar from '../components/navbar';
import { Banner } from '../components/banner';
import { useQuery, gql } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import AllcaseStudies from '../components/caseStudies';
import SingleListingBottomSlider from '../components/singleListingBottomSlider';
import { useSelector } from 'react-redux';

const caseStudy = gql`
query CaseStudy($slug: String){
    caseStudies(filters: { slug: {eq: $slug} }){
      data{
        id,
        attributes{
            Image{
                data{
                    attributes{
                        formats:url
                    }
                }
            },
            bottomImages{
                data{
                    attributes{
                        formats:url
                    }
                }
            },
            title,
            slug,
            subtitle,
            Outcomes,
            category,
            body
        }
      }
    }
  }
`

const CaseStudyDetail = () => {
    const { status, token } = useSelector((state) => state.user);
    const navigate = useNavigate();

    if (!token) {
        navigate("/login")
    }



    const { slug } = useParams();
    useEffect(() => {
        window.scrollTo(0, 0);

    }, [slug])
    const { loading, error, data } = useQuery(caseStudy, {
        variables: { slug: slug }
    });
    const caseStudyData = data?.caseStudies?.data[0]?.attributes
    return (
        <div>
            <Navbar />
            {/* <Banner heading={"Tesla Sale Case study"} img={'/assets/img/casestudy-detail-banner.png'} /> */}

            {/* <Banner heading={caseStudyData?.title} img={`${process.env.REACT_APP_API_BASEURL}${caseStudyData?.Image?.data?.attributes?.formats}`} /> */}

            <div className='position-relative featuredImage csDetail'>
                <div className='overlay'></div>
                {/* <img src={props.img ? props.img : '/assets/img/listings-banner.png'} className='img-fluid w-100' /> */}
                <img src={`${process.env.REACT_APP_API_BASEURL}${caseStudyData?.Image?.data?.attributes?.formats}`} className='img-fluid w-100' />
                {/* <h1 className='d-flex justify-content-center align-items-center mainHeading'>{props.heading}</h1> */}
            </div>

            <section className='container caseStudyDetails'>

                <h3>{caseStudyData?.title}</h3>
                <h6>{caseStudyData?.subtitle}</h6>
                <hr className='mb-4'></hr>
                <div dangerouslySetInnerHTML={{ __html: caseStudyData?.body }}></div>
                <br/>
                <div dangerouslySetInnerHTML={{ __html: caseStudyData?.Outcomes }}></div>
                {/* <hr className='mt-5 pb-5'></hr> */}
                <div className='container slBottomSlider'>
                    <SingleListingBottomSlider imgData={caseStudyData?.bottomImages?.data} />
                </div>
                <hr className='mt-2 pb-2'></hr>
                <AllcaseStudies />

            </section>

        </div>
    );
}

export default CaseStudyDetail;