import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const singleListingBottomSlider = ({imgData}) => {
    const settings = {
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        draggable: false,
    };
    const openLightBox = (event) =>{
      // ligbtBox open
      let lightBox = document.getElementById("lightBox");
      lightBox.classList.add("show-modal");


      // Render all carousel images into lightbox
      let images = document.querySelectorAll('.sliderImages');
      let urls = [];
      for (const image of images) {
        urls.push(image.src);
      }

      let container = document.getElementById('image-container');
      let dataIndex= 1;
      if(container.hasChildNodes()){
        container.innerHTML = '';
        for (let i = 0; i < urls.length; i++) {
          let img = document.createElement('img');
          img.src = urls[i];
          img.setAttribute('data-index', [dataIndex++]);
          container.appendChild(img);
        }
      }else{
        for (let i = 0; i < urls.length; i++) {
          let img = document.createElement('img');
          img.src = urls[i];
          img.setAttribute('data-index', [dataIndex++]);
          container.appendChild(img);
        }
      }


      //Find src and add class
      let src = event.target.getAttribute('src');
      let parentContainers = document.querySelectorAll('#image-container');
      parentContainers.forEach(function(parentContainer) {
        let childImages = parentContainer.querySelectorAll('img');
        childImages.forEach(function(childImage) {
          if (childImage.src.endsWith(src)) {
            childImage.classList.add('active'); 
          }
        });
      });


      // Total images get in digit
      let totalImg = document.getElementById("totalImg")
      totalImg.innerText = urls.length;
      counter();
    }

    const nextSlide = () =>{
      let items = document.querySelectorAll('#image-container img');
      for (let i = 0; i < items.length; i++) {
        let item = items[i];
        if (item.classList.contains('active')) {
          let nextItem = item.nextElementSibling;
          if(nextItem !== null){
            item.classList.remove('active');
          }
          if (nextItem) {
            nextItem.classList.add('active');
            break;
          }
        }
      }
      counter();
    }


    const prevSlide = () =>{
      let items = document.querySelectorAll('#image-container img');
      for (let i = 0; i < items.length; i++) {
        let item = items[i];
        if (item.classList.contains('active')) {
          let nextItem = item.previousElementSibling;
          if(nextItem !== null){
            item.classList.remove('active');
          }
          if (nextItem) {
            nextItem.classList.add('active');
            break;
          }
        }
      }
      counter();
    }


    const counter = () =>{
      let active = document.querySelector('#image-container .active');
      let getIndex = active.getAttribute('data-index');
      let counter = document.getElementById('counter');
      counter.innerText = getIndex;
    }


    const closeLightBox = () =>{
      let lightBox = document.getElementById("lightBox");
      lightBox.classList.remove("show-modal");
    }
  
    return (
      <div>
        <Slider {...settings}>

         { imgData?.map((item, index)=>{
          debugger
          return(

          <div className='focus-hide'>
          <img src={`${process.env.REACT_APP_API_BASEURL}${item?.attributes?.formats}`} className="img-fluid w-100 sliderImages" onClick={openLightBox}/>
          </div>
          )
         })
         }
          
          {/* Add more slides as needed */}
        </Slider>

        {/* modal start */}

        <div className="modal lightBox" id="lightBox">
          <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                  <div className="modal-header">
                  <div className='text-white'><span id='counter'></span>/<span id='totalImg'></span></div>
                    <button type="button" className="close-button" onClick={closeLightBox}><span aria-hidden="true"><img src='/assets/img/single-listing/ligtBox-close.png' className='img-fluid'/></span></button>
                  </div>
                  <div className="modal-body">
                      <div className='ligtBoxContent'>
                        <span><img src='/assets/img/light-left-arrow.png' className='img-fluid' id='leftArrow' onClick={prevSlide}/></span>
                        <div id='image-container'>
                        {/* <img src="/assets/img/single-listing/single-listing-04.png" className="img-fluid"/>
                        <img src="/assets/img/single-listing/single-listing-05.png" className="img-fluid"/>
                        <img src="/assets/img/single-listing/single-listing-06.png" className="img-fluid"/>
                        <img src="/assets/img/single-listing/single-listing-06.png" className="img-fluid "/> */}
                        </div>
                        <span><img src='/assets/img/light-right-arrow.png' className='img-fluid' id='rightArrow' onClick={nextSlide}/></span>
                      </div>
                  </div>
              </div>
          </div>
        </div>

        {/* modal end */}



      </div>
    );
  };
  
  export default singleListingBottomSlider;
  