import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { useLocation, NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const TeamsData = gql`
query Teams{
    teams(pagination: {limit:-1}){
        data{
          id
          attributes{
            title,
            slug,
            designation,
            phone,
            mobile, 
            email,
            licence,
            Index,
            body,
            profile{
              data{
                attributes{
                  formats:url
                }
              }
            }
          }
        }
      }
      }
`


export const Teams = () => {
  const { slug } = useParams()
  const { loading, error, data } = useQuery(TeamsData);
  // const teamData = data?.teams?.data?.slice(0, 2);
  const teamData = data?.teams?.data.filter((x) => x.attributes.slug != slug)
  const completeData = data?.teams?.data?.slice()
  const location = useLocation()
  window.scrollTo(0, 0);


  // teamData?.sort(function (a, b) {
  //   var indexA = parseInt(a?.attributes?.Index, 10);
  //   var indexB = parseInt(b?.attributes?.Index, 10);
  //   return indexA - indexB;
  // });

  // completeData?.sort(function (a, b) {
  //   var indexA = parseInt(a?.attributes?.Index, 10);
  //   var indexB = parseInt(b?.attributes?.Index, 10);
  //   return indexA - indexB;
  // });

  if (error) {
    return <div>Error: {error?.message}</div>;
  }
  return (
    <>
      {location.pathname === '/team' ?
        completeData?.slice().sort((a, b) => parseInt(a.attributes.Index) - parseInt(b.attributes.Index)).map((team, index) => {

          return (
            <div id={index} className="card">
              <div className='position-relative'>
                <img className="card-img-top img-fluid w-100" src={`${process.env.REACT_APP_API_BASEURL}${team?.attributes?.profile?.data?.attributes?.formats}`} alt="Card image cap" />
              </div>
              <div className="card-body">
                {/* <div className='d-flex justify-content-between align-items-center'><h3>{team.attributes.title}</h3><span>{team.attributes.licence}</span></div> */}
                <div className='d-flex justify-content-between align-items-center'><h3>{team.attributes.title}</h3></div>
                <h4 className='mb-3'>{team.attributes.designation}</h4>
                <p><img src='/assets/img/about-t-icon-01.png' className='img-fluid mb-2' />  {team.attributes.phone}</p>
                {!team.attributes.mobile ? '' :
                  <>
                    <p className='mb'><img src='/assets/img/about-t-icon-02.png' className='img-fluid mb-2' />{team.attributes.mobile}</p>
                  </>}
                <p><img src='/assets/img/about-t-icon-03.png' className='img-fluid mb-2' />  {team.attributes.email}</p>
                {!team.attributes.licence ? '' :
                  <>
                    <p><img src='/assets/img/lic-icon.png' className='img-fluid mb-2' />  {team.attributes.licence}</p>
                  </>}
                <hr></hr>
                <NavLink to={`/team-details/${team.attributes.slug}`}>More</NavLink>

              </div>
            </div>
          )
        }) :

        teamData?.sort((a, b) => parseInt(a.attributes.Index) - parseInt(b.attributes.Index)).slice(0, 3).map((team, index) => {
          return (
            <div id={index} className="card">
              <div className='position-relative'>
                <img className="card-img-top img-fluid w-100" src={`${process.env.REACT_APP_API_BASEURL}${team?.attributes?.profile?.data?.attributes?.formats}`} alt="Card image cap" />
              </div>
              <div className="card-body">
                {/* <div className='d-flex justify-content-between align-items-center'><h3>{team.attributes.title}</h3><span>{team.attributes.licence}</span></div> */}
                <div className='d-flex justify-content-between align-items-center'><h3>{team.attributes.title}</h3></div>
                <h4 className='mb-3'>{team.attributes.designation}</h4>
                <p><img src='/assets/img/about-t-icon-01.png' className='img-fluid mb-2' />  {team.attributes.phone}</p>

                {!team.attributes.mobile ? '' :
                  <>
                    <p className='mb'><img src='/assets/img/about-t-icon-02.png' className='img-fluid mb-2' /> {team.attributes.mobile}</p>
                  </>}
                <p><img src='/assets/img/about-t-icon-03.png' className='img-fluid mb-2' />  {team.attributes.email}</p>
                {!team.attributes.licence ? '' :
                  <>
                    <p><img src='/assets/img/lic-icon.png' className='img-fluid mb-2' />  {team.attributes.licence}</p>
                  </>}
                <hr></hr>
                <NavLink to={`/team-details/${team.attributes.slug}`}>More</NavLink>
              </div>
            </div>
          )
        })
      }

    </>
  )
}