import React, { useEffect, useState } from 'react';
import swal from "sweetalert";
import Navbar from '../components/navbar';
import SlickSlider from '../components/SlickSlider';
import { ListingCard } from '../components/listingCard';
import { About } from '../components/about';
import SplideSlider from '../components/SplideSlider';
import { Newsletter } from '../components/newsletter';
import Arr from '../components/data'
import { useQuery, gql } from '@apollo/client';
import { useNavigate,NavLink } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';


const Images = gql`
query CaseStudy {
    home {
      data {
        attributes {
          Banner {
            data {
              attributes {
                formats: url
              }
            }
          }
          ExchangeImage {
            data {
              attributes {
                formats: url
              }
            }
          }
          CaseStudies {
            data {
              attributes {
                formats: url
              }
            }
          }
        }
      }
    }
  }
`



const Home = () => {
  const navigate = useNavigate();
  const { status, token } = useSelector((state) => state.user);

  const [isVisible, setVisible] = useState(true);
  const [displayText, setText] = useState("");
  const { loading, error, data } = useQuery(Images);
  const constraction = false

  const ExchangeImageUrl = data?.home?.data?.attributes?.ExchangeImage?.data?.attributes?.formats
  const CaseStudiesUrl = data?.home?.data?.attributes?.CaseStudies?.data?.attributes?.formats
  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const verified = urlParams.get('verified');
  //   if (verified == 'true') {
  //     toast.success("Your email has been confirmed successfully.");
  //   }
  // }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const verified = urlParams.get('verified');
    if (verified == 'true') {
      let swalInst;
      const showAlert = async () => {
        swalInst = swal({
          text:
            "Congratulations on successfully verifying your email and completing the registration process! Your account is now awaiting approval from our admin team. Once approved, you'll have full access to the Century Partners website.",
          buttons: {
            // cancel: "Continue with bag",
            catch: {
              text: "Close",
              value: "Okay"
            }
          }
        });
        const result = await swalInst;
        // handle your actions here
        switch (result) {
          case "Okay":
            // clear everything here!!
            console.log("clearing...");
            setText("cleared!");
            break;
          default:
            console.log("dismissed");
            setText("dismissed!");
        }
        // always hide
        setVisible(true);
        console.log(result);
      };
      if (isVisible) {
        showAlert();
      } else {
        if (swalInst) {
          swalInst.cancel();
        }
      }
    }
  }, [isVisible]);

  const OnViewCaseStudiesClick = (e) => {
    debugger
    e.preventDefault()
    navigate(token ? '/case-study' : '/register')
    window.scrollTo(0, 0);
}


  if (constraction) return <img src='/assets/img/UnderConstruction.jpg' />
  return (

    <div>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
      <Navbar />
      <>
        {/* HERO SECTION */}
        {/* <div className="slider HeaderSlider">
                    <div className="slide">
                        <img src="/assets/img/hero-02.png" className="img-fluid w-100" />
                    </div>
                    <div className="slide">
                        <img src="/assets/img/hero-01.png" className="img-fluid w-100" />
                    </div>
                    <div className="slide">
                        <img src="/assets/img/hero-02.png" className="img-fluid w-100" />
                    </div>
                </div> */}
        <div className='HeaderSlider'>
          <SlickSlider />
        </div>

        <About />

        {/* CARD CAROUSEL SECTION */}
        <section className='container-fluid cardCarouselSec'>
          {/* <img src='/assets/img/home-carousel-watermark.png' className='img-fluid waterMark' /> */}
          <div className='container mb-5'>
            <h2 className='sub-heading'>Current <span>Listings</span></h2>
          </div>

          <div id="cardCarousel" className='container'>
            <SplideSlider />
          </div>

          {/* <div id="cardCarousel" className="splide">
                        <div className="splide__track">
                            <ul className="splide__list">
                                
                                {
                                    Arr.map((item) => {
                                        return (
                                            <li className="splide__slide">
                                                <>
                                                    <ListingCard data={item} key={item.id} />
                                                </>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div> */}

          <div className='text-center'>
            <a href="/listings" className="btn btn-secondary mt-5" type="submit">View All Properties</a>
          </div>
        </section>

        {/* PROJECT SECTION */}
        <section className="container-fluid projectSec">
          <div className="d-flex flex-wrap">
            <div>
              <NavLink className="position-relative" to="/exchange">
                {/* <a href="#" className="position-relative"> */}
                <img src={`${process.env.REACT_APP_API_BASEURL}${ExchangeImageUrl}`} className="img-fluid w-100" />
                <div>
                  <h2>1031 Exchanges</h2>
                  <button className="btn">Learn More</button>

                  {/* <NavLink className="btn" to="/exchange">Learn More</NavLink> */}
                </div>
                {/* </a> */}
              </NavLink>
            </div>

            <div>
              <NavLink onClick={OnViewCaseStudiesClick} className="position-relative">
                {/* <a href="#" className="position-relative"> */}
                <img src={`${process.env.REACT_APP_API_BASEURL}${CaseStudiesUrl}`} className="img-fluid w-100" />
                <div>
                  <h2>Case Studies</h2>
                  <button className="btn">Learn More</button>
                  {/* <NavLink className="btn" to="/case-study">Learn More</NavLink> */}
                </div>
                {/* </a> */}
              </NavLink>
            </div>
          </div>
        </section>

        {/* NEWSLETTER SECTION */}
        <Newsletter />
      </>

    </div>
  );
}

export default Home;