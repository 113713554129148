import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { ListingCard } from '../components/listingCard';
import Arr from '../components/data'
import { useQuery, gql } from '@apollo/client';


const AllListings = gql`
query Listings{
    listings{
      data{
        id
        attributes{
          MainImage{
            data{
              attributes{
                formats:url
              }
            }
          },
         price,
         Title,
         Location,
          CapRate,
          Size,
          Tenancy,
          LeaseTerm,
          slug,
          Index,
          NOI
          
        }
      }
    }
     }	
`

const SplideSlider = () => {
  const settings = {
    type: 'loop',
    perPage: 3,
    perMove: 1,
    autoplay: false,
    interval: 2000,
    pagination: true,
    drag: true,
    gap: '38px',
    breakpoints: {
      520: {
        perPage: 1,
      },
      991: {
        perPage: 2
      },
    }
  };
  const { loading, error, data } = useQuery(AllListings);
  
  const LisitngsData =   data?.listings?.data.slice().sort((a, b) => parseInt(a.attributes.Index) - parseInt(b.attributes.Index))
  debugger
  return (
    <Splide options={settings}>
      {
        LisitngsData?.map((item) => {
          return (
            <SplideSlide>
              <>
                <ListingCard data={item.attributes} id={item.id} key={item.id} />
              </>
            </SplideSlide>
          )
        })
      }
    </Splide>
  );
};

export default SplideSlider;
