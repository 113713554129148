
import Navbar from '../components/navbar';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser, clearUser } from '../redux/features/user/userSlice';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { useQuery, gql } from '@apollo/client';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import PasswordEye from '../components/passwordEye';


const bannerData = gql`
    query Banner{
        banner{
            data{
                attributes{
                    Signup{
                        data{
                            attributes{
                                formats:url
                            }
                        }
                    },
                }
            }
        }
    }`


const Register = () => {
  const { loading: BannerLoading, error: Error, data } = useQuery(bannerData);
  const ImageUrl = data?.banner?.data?.attributes?.Signup?.data?.attributes?.formats
  console.log(data?.banner?.data?.attributes?.Signup?.data?.attributes?.formats, "<----BANNER DATA")
  // const { status, error,token } = useSelector((state) => state.user);
  const email = localStorage.getItem("email");
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    Name: '',
    email: email ? email : '',
    password: '',
    UserRole: ''
  });
  const displayLoginNotification = () => {
    toast.success("Please check your inbox or spam for verification");
  };
  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!formData.Name) {
      errors.Name = 'Name is required';
      isValid = false;
    }

    if (!formData.email) {
      errors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Invalid email address';
      isValid = false;
    }

    if (!formData.password) {
      errors.password = 'Password is required';
      isValid = false;
    }
    if (!formData.UserRole) {
      errors.UserRole = 'Role is required';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    if (validateForm()) {
      try {
        debugger
        formData.username = formData.email;
        const response = await axios.post(`${process.env.REACT_APP_API_BASEURL}/api/auth/local/register`, formData);
        if (response.statusText === "OK") {

          localStorage.removeItem('email');

          displayLoginNotification();
          setLoading(false)
          setError("")
          setFormData({ Name: '', email: '', password: '', })
          localStorage.removeItem('email');
        }
      } catch (error) {
        debugger
        setLoading(false)
        if (error.response.data.error.message == "Email or Username are already taken") {

          setError('Email is already taken')
        } else {

          setError(error.response.data.error.message)
        }
      }

    } else {
      setLoading(false)

    }
  };
  return (
    <div>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />

      <div className='secondary-header'>
        <Navbar />
      </div>

      <section className="container-fluid signUpSec">
        <div className="row">
          <div className="col-md-6">
            <h2 className="sub-heading">Create your <span>account</span></h2>
            <hr></hr>
            <form onSubmit={handleSubmit}>
              <div className="form">
                <div className="input-group">
                  <input
                    type="text"
                    placeholder='Name *'
                    className="form-control"
                    name="Name"
                    autoComplete='false'
                    value={formData.Name}
                    onChange={handleChange} />
                </div>
                {errors.Name && <p className='text-danger'>{errors.Name}</p>}

                <div className="input-group">
                  <input type="email" className="form-control" name="email" id="email" placeholder='Email *'
                    value={formData.email}
                    defaultValue={email ? email : formData.email}
                    onChange={handleChange}

                  />

                </div>
                {errors.email && <p className='text-danger' >{errors.email}</p>}

                <div className="input-group">
                  {/* <input type="password" className="form-control" name="password" id="password" placeholder='Password *'
                    value={formData.password}
                    onChange={handleChange}

                  /> */}
                  <PasswordEye placeholder='Password *' name="password" value={formData.password} onChange={handleChange} />

                </div>
                {errors.password && <p className='text-danger'>{errors.password}</p>}

                <div className="input-group">
                  {/* <input
                    type="text"
                    placeholder='Role'
                    className="form-control"
                    name="UserRole"
                    autoComplete='false'
                    value={formData.UserRole}
                    onChange={handleChange} /> */}

                  <select className="form-control" name="UserRole" onChange={handleChange}>
                    <option value="">Select Role</option>
                    <option value="Broker">Broker</option>
                    <option value="Principal">Principal</option>
                  </select>
                </div>
                {errors.UserRole && <p className='text-danger'>{errors.UserRole}</p>}
                <div className="input-group">

                  <button type="submit" className="btn btn-primary w-100" value="Login Now">
                    {loading ? <div class="spinner-border text-light" role="status">
                    </div> : "Register"
                    }
                  </button>
                </div>
                {error && <p className='text-danger'>{error}</p>}

                <p>Already have an account? <NavLink to="/login">Login</NavLink></p>

              </div>
            </form>
          </div>
          <div className="col-md-6 text-end">
            {BannerLoading ? <p>Loading</p> :
              <img src={`${process.env.REACT_APP_API_BASEURL}${ImageUrl}`} className="img-fluid w-100 h-100 object-fit-cover" />}
          </div>
        </div>
      </section>

    </div>
  );
}

export default Register;