import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

export const Newsletter = () => {

    const [btnLoading, setLoading] = useState(false)

    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        Email: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validateForm = () => {
        let errors = {};
        let isValid = true;

        if (!formData.Email) {
            errors.Email = 'Email is required';
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        debugger
        if (validateForm()) {
            setErrors({})

            try {

                setLoading(true)
                const reqPayload = {
                    data: formData

                }
                debugger
                const response = await axios.post(`${process.env.REACT_APP_API_BASEURL}/api/news-letters`, reqPayload);

                if (response.statusText === "OK") {
                    setLoading(false)
                     toast.success("Success");
                    // navigate("/login")
                }
            } catch (error) {
                setLoading(false)
                toast.error(error.response.data.error.message);
            }
        }
    }

    return (
        <>
            <section className="container newsLetterSec">
                <h2 className='sub-heading'>Connect <span>With us</span></h2>
                <p>Get the Latest Market Updates and Exclusive Inventory</p>
                <form onSubmit={handleSubmit}>
                    <div className="input-group justify-content-between align-items-end">

                        <input
                            type="email"
                            className="form-control"
                            name="Email"
                            placeholder="Your email"
                            onChange={handleChange} />
                        <span className="input-group-btn">
                            <button type="submit" className="btn btn-secondary" >
                                {btnLoading ? <div className="spinner-border text-light" role="status">
                                </div> : "Submit"
                                }
                            </button>
                        </span>
                    </div>
                    {errors.Email && <span className='text-danger'>{errors.Email}</span>}
                </form>
            </section>
        </>
    )
}
