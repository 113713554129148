import React from 'react';
import Navbar from '../components/navbar';
import { Banner } from '../components/banner';
import { About } from '../components/about';
import { Newsletter } from '../components/newsletter';
import { Teams } from '../components/teams';
import { NavLink } from 'react-router-dom';


const AboutUs = () => {

    return (
        <>
            <Navbar />
            <Banner heading={"About"} />
            <About />


            {/* TEAM CARD SECTION */}
            <section className='container-fluid teamCardSec'>
                {/* <img src='/assets/img/about-team-watermark.png' className='img-fluid waterMark' /> */}
                <div className='container'>
                    <h2 className='sub-heading mb-5'>Our <span>Team</span></h2>

                    <div className='d-flex justify-content-between align-items-center flex-wrap w-100 mb-5'>

                        <Teams />

                    </div>

                    <div className='text-center'>
                        {/* <a href="/team" className="btn btn-secondary">See all Team Members</a> */}
                        <NavLink className="btn btn-secondary" to="/team">See all Team Members</NavLink>
                    </div>
                </div>
            </section>


            <Newsletter />
        </>
    );
}

export default AboutUs;