import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client"
import './App.css';
import Home from './pages/home';
import Footer from './components/footer';
import AboutUs from './pages/aboutUs';
import CaseStudy from './pages/caseStudy';
import CaseStudyDetail from './pages/caseStudyDetail';
import Contact from './pages/contact';
import Exchange from './pages/exchange';
import Listings from './pages/listings';
import SingleListing from './pages/singleListing';
import Team from './pages/team';
import TeamDetail from './pages/teamDetail';
import Register from './pages/register';
import TenantAdvisory from './pages/tenantadvisory';
import Login from './pages/login';
import ResetPassword from './pages/resetPassword';
import { CustomPage } from './pages/customPage';

// apollo client
const client = new ApolloClient({
  uri:`${process.env.REACT_APP_API_BASEURL}/graphql`,
  cache: new InMemoryCache()
})


function App() {
 
  return (
    <div >
      <Router>
        <ApolloProvider client = {client}>
        <div className="App">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about-us" element={<AboutUs />} />
            <Route exact path="/case-study" element={<CaseStudy />} />
            <Route exact path="/case-study-details/:slug" element={<CaseStudyDetail />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/exchange" element={<Exchange />} />
            <Route exact path="/listing/:slug" element={<SingleListing />} />
            <Route exact path="/listings" element={<Listings />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/reset-password" element={<ResetPassword />} />
            <Route exact path="/team" element={<Team />} />
            <Route exact path="/team-details/:slug" element={<TeamDetail />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/tenant-advisory" element={<TenantAdvisory />} />
            <Route exact path="/test" element={<CustomPage />} />
          </Routes>
          { <Footer /> }
        </div>
        </ApolloProvider>
      </Router>
    </div>
  );
}

export default App;
