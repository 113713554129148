import React, { useEffect, useState } from 'react';
import Navbar from '../components/navbar';
import { Banner } from '../components/banner';
import { useQuery, gql } from '@apollo/client';
import { toast } from 'react-toastify';
import axios from 'axios';

const ContactTeam = gql`
query Teams{
    teams(filters: { OnContact: {eq: true} }){
        data{
          id
          attributes{
            title,
            designation,
            phone,
            mobile,
            email,
            licence,
            Index,
            profile{
              data{
                attributes{
                  formats:url
                }
              }
            },
           
          }
        }
      }
 }
`

const ContactDetail = gql`
query Teams{
    contact{
      data{
        attributes{
          Address,
          Phone,
          Email,
          Linkedin,
          Instagram,
          Linktree,
          LIC,
          MeetPartners,
          MapLink
        }
      }
    }
}
`

const Contact = () => {
    // const [ContactTeams, setContactTeams] = useState();
    // const [ContactDetails, setContactDetails] = useState();
    const [btnLoading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        Name: '',
        LastName: '',
        Email: '',
        Subject: '',
        Message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true)
            const reqPayload = {
                data: formData

            }

            const response = await axios.post(`${process.env.REACT_APP_API_BASEURL}/api/contact-responses`, reqPayload);

            if (response.statusText === "OK") {
                setLoading(false)
                toast.success("Query has been sent.");
                // navigate("/login")
            }
        } catch (error) {
            debugger
            toast.error(error.response.data.error.message);
        }
    }

    const { loading, error, data } = useQuery(ContactTeam);
    const { loading: Loading, error: Error, data: Data } = useQuery(ContactDetail);




    useEffect(() => {

        // setContactTeams(data?.teams?.data)
        // setContactDetails(Data?.contact?.data?.attributes)
    }, []);

    const ContactTeams = data?.teams?.data
    const ContactDetails = Data?.contact?.data?.attributes

    return (
        <div>
            <Navbar />
            <Banner heading={"Contact"} />

            <section className="container contactSec">

                <div className="row mb-5">
                    <div className="col-md-6 pt-md-5">
                        <h2 className="sub-heading">Contact <span>info</span></h2>
                        <ul className="mt-4">
                            {!ContactDetails?.Address ? '' :
                                <>
                                    <li><img src="/assets/img/contact-map.png" className="img-fluid" /><p>{ContactDetails?.Address}</p></li>
                                </>
                            }
                            {!ContactDetails?.Phone ? '' :
                                <>
                                    <li><img src="/assets/img/contact-phone.png" className="img-fluid" /><p>{ContactDetails?.Phone}</p></li>
                                </>
                            }
                            {!ContactDetails?.LIC ? '' :
                                <>
                                    <li><img src="/assets/img/contact-lic.png" className="img-fluid" /><p>{ContactDetails?.LIC}</p></li>
                                </>
                            }

                        </ul>
                        <hr></hr>
                        <h2 className="submini-heading">Get <span>Connected</span></h2>
                        <div className="d-flex align-items-center contactSocialMedia">
                            {/* <img src="/assets/img/contact-fb.png" className="img-fluid" /> */}

                            {!ContactDetails?.Linkedin ? '' :
                                <>
                                    <a href={ContactDetails?.Linkedin}> <img src="/assets/img/contact-linkedin.png" className="img-fluid" /></a>
                                </>
                            }

                            {/* <img src="/assets/img/contact-youtube.png" className="img-fluid" /> */}
                            {/* <a href={ContactDetails?.Instagram}> <img src="/assets/img/contact-insta.png" className="img-fluid" /></a> */}

                            {!ContactDetails?.Linktree ? '' :
                                <>
                                    <a href={ContactDetails?.Linktree}> <img src="/assets/img/linktree-logo-icon-01.png" className="img-fluid" /></a>
                                </>
                            }
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="teamBox">
                                    <h2 className="sub-heading">Century <span>Partners</span></h2>

                                    {ContactTeams?.slice().sort((a, b) => parseInt(a.attributes.Index) - parseInt(b.attributes.Index)).map((team, index) => {
                                        return (
                                            <div className="d-lg-flex justify-content-between mt-4">
                                                <div className="position-relative">
                                                    <img className="card-img-top img-fluid" src={`${process.env.REACT_APP_API_BASEURL}${team?.attributes?.profile?.data?.attributes?.formats}`} alt="Card image cap" />
                                                </div>
                                                <div className="card-body">
                                                    <h3>{team?.attributes?.title}</h3>
                                                    <h4 className="mb-2">{team?.attributes?.designation}</h4>
                                                    {team?.attributes?.mobile && <p><img src="/assets/img/about-t-icon-01.png" className="img-fluid mb-2" /><strong></strong>{team?.attributes?.mobile}</p>}
                                                    {/* {team?.attributes?.licence && <p className="mb"><img src="/assets/img/about-t-icon-02.png" className="img-fluid mb-2" /><strong></strong> {team?.attributes?.licence}</p>} */}
                                                    {team?.attributes?.email && <p><img src="/assets/img/about-t-icon-03.png" className="img-fluid mb-2" /><strong></strong> {team?.attributes?.email}</p>}
                                                    {team?.attributes?.licence && <p><img src="/assets/img/lic-icon.png" className="img-fluid mb-2" /><strong></strong> {team?.attributes?.licence}</p>}
                                                </div>
                                            </div>
                                        )
                                    })
                                    }


                                </div>
                            </div>
                        </div>
                        <div className='row mt-3 b-3'>
                            <div className='col-md-12'>
                                <a className='form-control btn btn-primary rounded' target='_blank' href={ContactDetails?.MeetPartners}>
                                    <span><img src='/assets/img/video.png' className='btnIcon' /></span>
                                    Meet with the Partners
                                </a>
                            </div>
                        </div>

                    </div>
                </div>



                <div className="row pt-5">
                    <div className="col-md-12">
                        <h2 className="sub-heading">Leave a <span>Message</span></h2>
                        <form onSubmit={handleSubmit}>
                            <div className="form row">
                                <div className="input-grp col-md-6">
                                    <input type="text" className="form-control" required name="Name" id="name" placeholder='First Name' onChange={handleChange} />
                                </div>

                                <div className="input-grp col-md-6">
                                    <input type="text" className="form-control" name="LastName" id="lastName" placeholder='Last Name' onChange={handleChange} />
                                </div>

                                <div className="input-grp col-md-6">
                                    <input type="email" className="form-control" required name="Email" id="email" placeholder='Email' onChange={handleChange} />
                                </div>

                                <div className="input-grp col-md-6">
                                    <input type="text" className="form-control" required name="Subject" id="subject" placeholder='Subject' onChange={handleChange} />
                                </div>

                                <div className="input-grp col-md-12">
                                    <textarea name="Message" className="form-control" required id="details" cols="30" rows="10" placeholder='Details / Notes' onChange={handleChange}></textarea>
                                </div>

                                <div className="input-group">
                                    <button type="submit" className="btn btn-primary" value="Login Now">{btnLoading ? <div class="spinner-border text-light" role="status">
                                    </div> : "Submit"
                                    }</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>


            <section className="container-fluid contactMap" dangerouslySetInnerHTML={{ __html: ContactDetails?.MapLink.replace('<p>', '').replace('</p>', '') }}>
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.385800879323!2d-118.42033612340238!3d34.059623117338916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2bb8b630bd9b5%3A0x30a5194a0b831436!2sPegasus%20Investments%20Real%20Estate%20Advisory%20Inc.!5e0!3m2!1sen!2s!4v1694533425884!5m2!1sen!2s" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
            </section>



        </div>
    );
}

export default Contact;