import React from 'react';
import Navbar from '../components/navbar';
import { Teams } from '../components/teams';
import { useQuery, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';

// import { Banner } from '../components/banner';

const SingleTeamsData = gql`
query Teams($slug: String){
    teams(filters: { slug: {eq: $slug} }){
        data{
          id
          attributes{
            title,
            slug,
            designation,
            phone,
            mobile, 
            body,
            email,
            LinkedIn,
            licence,
            body,
            profile{
              data{
                attributes{
                  formats:url
                }
              }
            },
            vcard{
              data{
                attributes{
                  formats:url
                }
              }
            },
          }
        }
      }
      }
`
const TeamDetail = () => {
  const { slug } = useParams()
  const { loading, error, data } = useQuery(SingleTeamsData, {
    variables: { slug: slug }
  });
  const singleTeam = data?.teams?.data[0]?.attributes

  return (
    <>
      <div className='secondary-header'>
        <Navbar />
      </div>
      {/* <Banner heading={"Team dsas"} /> */}


      {/* TEAM DETAILS SECTION */}
      {data && <section className='container teamDetails'>
        <div className='row'>
          <div className='col-md-6 text-center'>
            <img src={`${process.env.REACT_APP_API_BASEURL}${singleTeam.profile?.data?.attributes?.formats}`} className='img-fluid sdetImg' />
            <div className='d-flex align-items-center justify-content-center flex-wrap pt-4'>
              {/* <a href={`${process.env.REACT_APP_API_BASEURL}${singleTeam.vcard?.data?.attributes?.formats}`} download={`${process.env.REACT_APP_API_BASEURL}${singleTeam.vcard?.data?.attributes?.formats}`} className='btn btn-download'>Download VCard</a> */}
              <a href={'mailto:' + singleTeam.email} className='btn btn-primary'>Contact {singleTeam.title?.split(' ')[0]}</a>
            </div>
            <div className='d-flex align-items-center justify-content-center pt-4 teamDetailsSocial'>
              {/* <a href='#'><img src='/assets/img/team-details-fb.png' className='img-fluid' /></a> */}
              <a href={singleTeam.LinkedIn} ><img src='/assets/img/team-details-linkedin.png' className='img-fluid' /></a>
              {/* <a href='#'><img src='/assets/img/team-details-youtube.png' className='img-fluid' /></a> */}
            </div>
          </div>
          <div className='col-md-6'>
            <div className="card">
              <div className="card-body">
                {/* <div className='d-flex justify-content-between align-items-center'><h3>{singleTeam.title}</h3><span>{singleTeam.licence}</span></div> */}
                <div className='d-flex justify-content-between align-items-center'><h3>{singleTeam.title}</h3></div>
                <h4 className='mb-3'>{singleTeam.designation}</h4>
                <p><img src='/assets/img/about-t-icon-01.png' className='img-fluid mb-2' />{singleTeam.phone}</p>
  
                {!singleTeam.mobile ? '' : 
                  <>
                    <p className='mb'><img src='/assets/img/about-t-icon-02.png' className='img-fluid mb-2' />{singleTeam.mobile}</p>
                  </>
                }
                <p className='mb'><img src='/assets/img/about-t-icon-03.png' className='img-fluid mb-2' />{singleTeam.email}</p>
                {!singleTeam.licence ? '' :
                  <>
                    <p className='mb-3'><img src='/assets/img/lic-icon.png' className='img-fluid mb-2' />{singleTeam.licence}</p>
                  </>}
                <p className='full-description mt-3' dangerouslySetInnerHTML={{ __html: singleTeam.body }}></p>
              </div>
            </div>
          </div>
        </div>
      </section>



      }

      {/* TEAM CARD SECTION */}
      <section className='container-fluid teamCardSec'>
        {/* <img src='/assets/img/about-team-watermark.png' className='img-fluid waterMark' /> */}
        <div className='container'>
          <h2 className='sub-heading mb-5'>Our <span>Team</span></h2>

          {/* <div className='d-flex justify-content-between align-items-center flex-wrap w-100 mb-5'> */}
          <div className='d-flex justify-content-center flex-wrap w-100 mb-5'>

            <Teams />

          </div>

          <div className='text-center'>
            <a href="/team" className="btn btn-secondary">See all Team Members</a>
          </div>
        </div>
      </section>

    </>
  );
}

export default TeamDetail;