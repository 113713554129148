import React, { useEffect, useState } from 'react'
// import axios from 'axios'

export const CustomPage = () => {

    useEffect(() => {
        
        axios.post('http://localhost:1337/auth/local', {
            identifier: 'o.qazi90@gmail.com',
            password: 'Asdf123@',
        }).then(response => {
            console.log('User profile', response.data.user);
            console.log('User token', response.data.jwt);
        }).catch(error => {
            console.log('An error occurred:', error.response);
        });
    }, [])

    return (
        <>
            <iframe src='http://localhost:1337/admin/' ></iframe>
        </>
    )
}
