import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom';


export const ListingCard = ({ data, id }) => {

    const navigate = useNavigate();

    const RedirectToList = () => {
        debugger
        navigate(`/listing/${data.slug}`)
    }

    window.scrollTo(0, 0);
    return (
        <>
            <div className="card">
                <div className='position-relative'>
                    <div className='shade-hover' onClick={RedirectToList}></div>
                    <img className="card-img-top img-fluid w-100"  src={`${process.env.REACT_APP_API_BASEURL}${data?.MainImage?.data?.attributes?.formats}`} alt="Card image cap" />
                    <h6>Price: <span>{data.price}</span></h6>
                </div>
                <div className="card-body">
                    <div className='d-flex align-items-center justify-content-between flex-wrap listingHead'>
                        <div>
                            <h3>  <NavLink to={`/listing/${data.slug}`} title='Login or Register to download Offering Memorandum'>{data.Title}</NavLink></h3>

                            <h4>{data?.Location}</h4>
                        </div>
                        <div>
                            {/* <NavLink to={`/listing/${data.slug}`} className="text-red" title='Login or Register to download Offering Memorandum'>More</NavLink> */}
                        </div>
                    </div>
                    <hr></hr>
                    <div className='d-flex justify-content-between'>
                        <p>Cap Rate:<span>{data.CapRate}</span></p>
                        <p>NOI:<span>{data.NOI}</span></p>
                        <p>Lease Term:<span>{data.LeaseTerm}</span></p>
                    </div>
                </div>
            </div>
        </>
    )
}
