import React from 'react'
import { useLocation } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';

export const Banner = (props) => {

const bannerData = gql`
    query Banner{
        banner{
            data{
                attributes{
                    TenantAdvisory{
                        data{
                            attributes{
                                formats:url
                            }
                        }
                    },
                    About{
                        data{
                            attributes{
                                formats:url
                            }
                        }
                    },
                    Contact{
                        data{
                            attributes{
                                formats:url
                            }
                        }
                    },
                    Exchange{
                        data{
                            attributes{
                                formats:url
                            }
                        }
                    },
                    Teams{
                        data{
                            attributes{
                                formats:url
                            }
                        }
                    },
                    Listings{
                        data{
                            attributes{
                                formats:url
                            }
                        }
                    },
                    CaseStudies{
                        data{
                            attributes{
                                formats:url
                            }
                        }
                    }
                }
            }
        }
    }`

    const { loading, error, data } = useQuery(bannerData)
    
    const content = data?.banner?.data?.attributes
    // const GetBanner=()=>{
    //     if(location.pathname === '/team'){

    //     }
    // }
        return (
        <>
            {/* BANNER SECTION */}
            {/* <div className='position-relative featuredImage'>
                <img src='/assets/img/listings-banner.png' className='img-fluid w-100' />
                <h1 className='d-flex justify-content-center align-items-center mainHeading'>{props.heading}</h1>
            </div> */}
            <div className='position-relative featuredImage'>
                {/* <img src={props.img ? props.img : '/assets/img/listings-banner.png'} className='img-fluid w-100' /> */}
              <>
              {content ?<img src={`${process.env.REACT_APP_API_BASEURL}${content[props.heading]?.data?.attributes?.formats}`} className='img-fluid w-100' />:''}
              </>  
                {/* <h1 className='d-flex justify-content-center align-items-center mainHeading'>{props.heading}</h1> */}
            </div>
        </>
    )
}
