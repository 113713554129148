import React, { useEffect, useState } from 'react';
import Navbar from '../components/navbar';
import SingleListingTopSlider from '../components/singleListingTopSlider';
import SingleListingBottomSlider from '../components/singleListingBottomSlider';
import { useQuery, gql } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import axios from 'axios';


// const TeamsData = gql`
// query Teams{
//     teams{
//         data{
//           id
//           attributes{
//             title,
//             designation,
//             phone,
//             mobile, 
//             email,
//             location,
//             body,
//             profile{
//               data{
//                 attributes{
//                   formats:url
//                 }
//               }
//             }
//           }
//         }
//       }
//       }
// `

const SingleListingData = gql`
query Listings($slug: String){
    listings(filters: { slug: {eq: $slug} }){
      data{
        attributes{
          TopImages{
            data{
              attributes{
                formats:url
              }
            }
          },
          BottomImages{
            data{
              attributes{
                formats:url
              }
            }
          },
          Title,
          slug,
          Address,
          Address2,
          City,
          Location,
          LotSize,
          BuildingSize,
          YearBuild,
          ParkingSpace,
          Apn,
          price,
          CapRate,
          NOI,
          NetRentableArea,
          LeaseTerm,
          investmentHignlights,
          PropertyInformation,
          LocationInformation,
          OfferingSummary,
          RentSchedule,
          MapIFrame,
         teams{
            data{
              id
              attributes{
                profile {
                    data{
                        attributes{
                            formats:url
                        }
                    }
                }
                title,
                designation,
                phone,
                mobile,
                email,
                body,
                licence,
                Index
              }
            }
          },
          File{
            data{
              attributes{
                formats:url
              }
            }
          }
        }
      }
    }
  }	
`
const singleListing = () => {
    const { status, error: StateError, token, user } = useSelector((state) => state.user);
    const { slug } = useParams();
    const { loading, error, data } = useQuery(SingleListingData, {
        variables: { slug: slug }
    });
    // const { loading: tamloading, error: taamError, data: teamData } = useQuery(TeamsData);
    const [email, setEmail] = useState("")
    const listingData = data?.listings?.data[0]?.attributes
    const navigate = useNavigate();


    const [btnLoading, setLoading] = useState(false)

    const handleOffering = () => {

        // if (document.getElementById("txtEmail").checkValidity()) {
        //     localStorage.setItem("email", email);
        //     navigate("/register")
        // }
        
        if (document.getElementById("txtEmail").value && document.getElementById("txtEmail").checkValidity()) {
            setLoading(true)
            let url = `${process.env.REACT_APP_API_BASEURL}/api/om-download-users`
            axios.get(url)
                .then((resp) => {
                    
                    console.log(resp.data.data);
                    let recordExists = resp.data.data.filter((x) => x.attributes.Email == email).length > 0

                    if (recordExists) {
                        //download pdf
                        let downloadUrl = `${process.env.REACT_APP_API_BASEURL}${listingData?.File?.data?.attributes?.formats}`

                        handleDownload()
                        setLoading(false)

                        window.open(downloadUrl)
                    } else {
                        url = `${process.env.REACT_APP_API_BASEURL}/api/om-download-users`
                        axios.post(url, { data: { Email: email } })
                            .then((resp) => {
                                
                                //download pdf
                                let downloadUrl = `${process.env.REACT_APP_API_BASEURL}${listingData?.File?.data?.attributes?.formats}`
                                handleDownload()
                                setLoading(false)
                                window.open(downloadUrl)
                            })
                            .catch((error) => {
                                setLoading(false)
                                
                                console.log(error);
                            });
                    }

                })
                .catch((error) => {
                    setLoading(false)
                    
                    console.log(error);
                });
        } else {
            toast.error('Invalid Email Format');
            
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);

    }, [slug])


    const handleDownload = (event) => {
        
        const url = `${process.env.REACT_APP_API_BASEURL}/api/pdf-download-logs`
        if (event) {

            const data = { data: { user: user.email, property: listingData?.Title, file: listingData?.File?.data?.attributes?.formats, type: 'Registered' } }
            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${token}`
                }
            })
            // document.getElementById("txtEmail").value=''
        } else {

            const data = { data: { user: email, property: listingData?.Title, file: listingData?.File?.data?.attributes?.formats, type: 'Non-Registered' } }
            axios.post(url, data)
            document.getElementById("txtEmail").value=''
        }
    }

    return (

        <div>
            <ToastContainer
                position='top-right'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='light'
            />
            <div className='secondary-header'>
                <Navbar />
            </div>

            <div className='slTopSlider'>
                <SingleListingTopSlider imgData={listingData?.TopImages?.data} />
            </div>

            {/* Top Box Section start */}
            <section className="container mx1126 topBox">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="topBoxcontent01">
                            <h2>{listingData?.Title}</h2>
                            <h6>{listingData?.Address}</h6>
                            <hr></hr>
                            <a href="#iframeMap" className="btn w-50 bg-primary text-white"><img src="/assets/img/single-listing/map-icon.png" className="img-fluid" /> View on Google map</a>
                            {/*<p><span>{listingData?.Address2} |</span>{listingData?.City}</p> */}
                            {/* <div className="d-flex align-items-center iconList flex-wrap">
                                <div><img src="/assets/img/single-listing/lot-size.svg" className="img-fluid" /><p>Lot Size<br></br><span>{listingData?.LotSize}</span></p></div>
                                <div><img src="/assets/img/single-listing/building-size.svg" className="img-fluid" /><p>Building Size<br></br><span>{listingData?.BuildingSize}</span></p></div>
                                <div><img src="/assets/img/single-listing/built.svg" className="img-fluid" /><p>Year Built<br></br><span>{listingData?.YearBuild}</span></p></div>
                                <div><img src="/assets/img/single-listing/parking.svg" className="img-fluid" /><p>Parking Space<br></br><span>{listingData?.ParkingSpace}</span></p></div>
                                <div><img src="/assets/img/single-listing/apn.svg" className="img-fluid" /><p>APN<br></br><span>{listingData?.Apn}</span></p></div>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-4 bg-blue">
                        <div className="topBoxcontent02">
                            <div className="d-flex justify-content-between">
                                <div><h6>Price: <br></br><span>{listingData?.price}</span></h6></div>
                                <div><h6>Cap Rate: <br></br><span>{listingData?.CapRate}</span></h6></div>
                            </div>
                            <hr></hr>
                            <div className="d-flex justify-content-between">
                                <div><h6>NOI: <br></br><span>{listingData?.NOI}</span></h6></div>
                                <div><h6>Lease Term: <br></br><span>{listingData?.LeaseTerm}</span></h6></div>
                            </div>



                        </div>
                    </div>
                </div>
            </section >
            {/* Top Box Section end */}


            {/* Email section start  */}
            {
                !token ? <section className='container mx1126 emailBox'>
                    <div className='bg-grey'>
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="text-center">Offering <span>Memorandum</span></h2>
                                <p className="text-center">Please log in or begin registration by providing your email address.</p>
                                <div className="input-group">
                                    <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Email" id="txtEmail" />
                                    <span className="input-group-btn">
                                        <button onClick={handleOffering} className="btn" type="button" disabled={btnLoading} >
                                            {btnLoading ? <div class="spinner-border text-light" role="status">
                                            </div> : "Submit"
                                            }
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                    :
                    <section className='container mx1126 emailBox'>
                        <div className='bg-grey'>
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 className="text-center">Offering <span>Memorandum</span></h2>
                                    <p className="text-center">Download the Property files below.</p>
                                    <form method="get" action={`${process.env.REACT_APP_API_BASEURL}${listingData?.File?.data?.attributes?.formats}`} target="_blank">
                                        <button onClick={handleDownload} className="downloadBtn">{listingData?.Title} - OM</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
            }

            {/* PDF Download section start */}


            {/* Tab section start  */}

            {/* TEAM CARD SECTION */}
            <section className='container-fluid teamCardSec bg-white mt-0'>
                {/* <img src='/assets/img/about-team-watermark.png' className='img-fluid waterMark' /> */}
                <div className='container'>
                    <h2 className=' mb-5 text-center'>Exclusively Offered by</h2>
                    <div className='d-flex justify-content-center flex-wrap w-100 mb-5'>
                        {listingData?.teams?.data/*.slice().sort((a, b) => parseInt(a.attributes.Index) - parseInt(b.attributes.Index))*/.map((team) => {
                            return (

                                <div className="card">
                                    <div className='position-relative'>
                                        <img className="card-img-top img-fluid w-100" src={`${process.env.REACT_APP_API_BASEURL}${team?.attributes?.profile?.data?.attributes?.formats}`} alt="Card image cap" />
                                    </div>
                                    <div className="card-body">
                                        {/* <div className='d-flex justify-content-between align-items-center'><h3>{team.attributes.title}</h3><span>{team.attributes.licence}</span></div> */}
                                        <div className='d-flex justify-content-between align-items-center'><h3>{team.attributes.title}</h3></div>
                                        <h4 className='mb-3'>{team.attributes.designation}</h4>
                                        <p><img src='/assets/img/about-t-icon-01.png' className='img-fluid mb-2' />  {team.attributes.phone}</p>
                                        {!team.attributes.mobile ? '' :
                                            <>
                                                <p className='mb'><img src='/assets/img/about-t-icon-02.png' className='img-fluid mb-2' />{team.attributes.mobile}</p>
                                            </>}
                                        <p><img src='/assets/img/about-t-icon-03.png' className='img-fluid mb-2' />  {team.attributes.email}</p>
                                        <p><img src='/assets/img/lic-icon.png' className='img-fluid mb-2' />  {team.attributes.licence}</p>

                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </section>



            {/* Tab section end */}

            {/* Offer summary start */}
            {/* <section className='container mx1126 offerSummary'>
                <div className='bg-grey px-lg-5'>
                    <div className='row'>
                        <h2 className='mb-2'>Offering <span>Summary</span></h2>
                        <ul dangerouslySetInnerHTML={{ __html: listingData?.OfferingSummary.replace('<ul>', '').replace('</ul>', '') }}></ul>

                       <ul>
                                <li><span>Lease Commencement:</span><span>5/6/1994 </span></li>
                                <li><span>Lease Expiration:</span><span>9/30/2032 </span></li>
                                <li><span>Lease Term Remaiming:</span><span>9 Years</span></li>
                                <li><span>Current Monthly Base Rent:</span><span>$17,500</span></li>
                                <li><span>Current Annual Base Rent:</span><span>$210,000</span></li>
                                <li><span>Rental Increases:</span><span>$18,900 (October 2027) </span></li>
                                <li><span>Renewal Options:</span><span>Three 5-Year @ 8% Each option</span></li>
                                <li><span>Lease Type:</span><span>Absolute NNN Ground Lease</span></li>
                            </ul> 

                    </div>
                </div>
            </section> */}
            {/* offer summary end */}


            {/* table start */}
            {/* <section className='container mx1126 boxTable'>
                <div className='bg-grey px-lg-5' dangerouslySetInnerHTML={{ __html: listingData?.RentSchedule }}>
                   <table className="table">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">Rent Schedule</th>
                                <th scope="col">Monthly Rent</th>
                                <th scope="col">Annual Rent</th>
                                <th scope="col">Rent/SF</th>
                                <th scope="col">Cap Rate</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>2023- 2028</td>
                                <td>$13,306</td>
                                <td>$159,670</td>
                                <td>$68.82</td>
                                <td>6.00%</td>
                            </tr>
                            <tr>
                                <td>2023- 2028</td>
                                <td>$13,306</td>
                                <td>$159,670</td>
                                <td>$68.82</td>
                                <td>6.00%</td>
                            </tr>
                            <tr>
                                <td>2023- 2028</td>
                                <td>$13,306</td>
                                <td>$159,670</td>
                                <td>$68.82</td>
                                <td>6.00%</td>
                            </tr>
                            <tr>
                                <td>2023- 2028</td>
                                <td>$13,306</td>
                                <td>$159,670</td>
                                <td>$68.82</td>
                                <td>6.00%</td>
                            </tr>
                            <tr>
                                <td>2023- 2028</td>
                                <td>$13,306</td>
                                <td>$159,670</td>
                                <td>$68.82</td>
                                <td>6.00%</td>
                            </tr>
                            <tr>
                                <td>2023- 2028</td>
                                <td>$13,306</td>
                                <td>$159,670</td>
                                <td>$68.82</td>
                                <td>6.00%</td>
                            </tr>
                            <tr>
                                <td>2023- 2028</td>
                                <td>$13,306</td>
                                <td>$159,670</td>
                                <td>$68.82</td>
                                <td>6.00%</td>
                            </tr>
                            <tr>
                                <td className='br-left-50'>2023- 2028</td>
                                <td>$13,306</td>
                                <td>$159,670</td>
                                <td>$68.82</td>
                                <td className='br-right-50'>6.00%</td>
                            </tr>
                        </tbody>
                    </table> 
                </div>
            </section> */}
            {/* table end */}


            {/* <div className='container slBottomSlider'>
                <SingleListingBottomSlider imgData={listingData?.BottomImages?.data} />
            </div> */}


            {/* Map */}
            <section id="iframeMap" className="container-fluid listingMap" dangerouslySetInnerHTML={{ __html: listingData?.MapIFrame?.replace('<p>', '').replace('</p>', '') }}>
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.385800879323!2d-118.42033612340238!3d34.059623117338916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2bb8b630bd9b5%3A0x30a5194a0b831436!2sPegasus%20Investments%20Real%20Estate%20Advisory%20Inc.!5e0!3m2!1sen!2s!4v1694533425884!5m2!1sen!2s" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
            </section>

        </div >
    );
}

export default singleListing;