import React, { useEffect, useState } from 'react';
import Navbar from '../components/navbar';
import { Banner } from '../components/banner';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const Exchange = () => {
    const { status, token } = useSelector((state) => state.user);

    window.scrollTo(0, 0);
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const Handleredirect = () => {
        localStorage.setItem("currentCase", "Exchange");
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASEURL}/api/exchange?populate=*`);
                console.log(response.data);
                setData(response.data.data.attributes);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error)
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [])

    return (
        <div>
            <Navbar />
            <Banner heading={"Exchange"} />

            {loading ? (<p>Loading...</p>) :
                (
                    <>
                        <section className='container exchangeTopSec'>
                            <div className='row gx-lg-5 justify-content-between align-items-center pb-5'>
                                <div className='col-lg-6'>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: data?.Heading1 }}
                                    />
                                    <div
                                        dangerouslySetInnerHTML={{ __html: data?.Paragraph1 }}
                                    />
                                </div>
                                <div className='col-lg-6 text-lg-end'>
                                    <img src={`${process.env.REACT_APP_API_BASEURL}${data?.Image1.data.attributes.url}`} className='img-fluid' />
                                </div>
                            </div>
                            <div className='row mt-5 gx-lg-5 justify-content-between align-items-center r-reverse'>
                                <div className='col-lg-6 text-lg-start'>
                                    <img src={`${process.env.REACT_APP_API_BASEURL}${data?.Image2.data.attributes.url}`} className='img-fluid' />
                                </div>
                                <div className='col-lg-6'>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: data?.Heading2 }}
                                    />
                                    <div
                                        dangerouslySetInnerHTML={{ __html: data?.Paragraph2 }}
                                    />

                                </div>
                            </div>
                        </section>


                        <section className='container-fluid benefitSec'>
                            <div
                                dangerouslySetInnerHTML={{ __html: data?.Heading3 }}
                            />
                            <div className='d-flex justify-content-center flex-wrap'>
                                <div className='text-center'>
                                    <img src={`${process.env.REACT_APP_API_BASEURL}${data?.Box1Icon.data.attributes.url}`} className='img-fluid' />
                                    <h6>{data?.Box1Title}</h6>
                                </div>

                                <div className='text-center mt-40'>
                                    <img src={`${process.env.REACT_APP_API_BASEURL}${data?.Box2Icon.data.attributes.url}`} className='img-fluid' />
                                    <h6>{data?.Box2Ttile}</h6>
                                </div>

                                <div className='text-center'>
                                    <img src={`${process.env.REACT_APP_API_BASEURL}${data?.Box3Icon.data.attributes.url}`} className='img-fluid' />
                                    <h6>{data?.Box3Title}</h6>
                                </div>

                                <div className='text-center mt-40'>
                                    <img src={`${process.env.REACT_APP_API_BASEURL}${data?.Box4Icon.data.attributes.url}`} className='img-fluid' />
                                    <h6>{data?.Box4Title}</h6>
                                </div>
                            </div>


                        </section>


                        <section className='timeLine container-fluid'>
                            <div
                                dangerouslySetInnerHTML={{ __html: data?.ProcessTimeline }}
                            />
                            <div className='roadMap'>
                                <div className="container-t right">
                                    <div className="content">
                                        <h2>{data?.Title1}</h2>
                                    </div>
                                </div>
                                <div className="container-t left map-xz">
                                    <div className="content">
                                        <h2>{data?.Title2}</h2>
                                        <p>{data?.TitleParagraph2}</p>
                                    </div>
                                </div>
                                <div className="container-t right">
                                    <div className="content">
                                        <h2>{data?.Title3}</h2>
                                    </div>
                                </div>
                                <div className="container-t left map-xz">
                                    <div className="content">
                                        <h2>{data?.Title4}</h2>
                                        <p>{data?.TitleParagraph4}</p>
                                    </div>
                                </div>
                                <div className="container-t right">
                                    <div className="content">
                                        <h2>{data?.Title5}</h2>
                                    </div>
                                </div>
                                <div className="container-t left">
                                    <div className="content">
                                        <h2>{data?.Title6}</h2>
                                    </div>
                                </div>
                                <div className="container-t right">
                                    <div className="content">
                                        <h2>{data?.Title7}</h2>
                                    </div>
                                </div>
                                <div className="container-t left">
                                    <div className="content">
                                        <h2>{data?.Title8}</h2>
                                    </div>
                                </div>
                                <div className="container-t right map-x">
                                    <div className="content">
                                        <h2 className='mb-2'>{data?.Title9}</h2>
                                        <p className='txtsmall'>{data?.TitleParagraph9}</p>
                                    </div>
                                </div>
                                <div className="container-t left">
                                    <div className="content mb-0">
                                        <h2>{data?.Title10}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className='text-center mt-5'>
                                {<NavLink onClick={Handleredirect} to={token ? '/case-study' : '/register'} className="btn btn-secondary mt-4 mb-4" type="submit">1031 Exchange Case Studies </NavLink>}
                            </div>
                        </section>
                    </>
                )
            }

        </div>
    );
}

export default Exchange;