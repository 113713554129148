const Arr = [
    {
        id: 1,
        img: '/assets/img/home-property-01.png',
        price: '12,083,000',
        name: 'TESLA SALES, SERVICE & DELIVERY',
        address: 'ORLAND PARK , IL (CHICAGO)',
        size: '10,000',
        tenancy: 'Single Tenant',
    },
    {
        id: 2,
        img: '/assets/img/home-property-02.png',
        price: '22,083,000',
        name: 'TESLA SALES, SERVICE & DELIVERY',
        address: 'ORLAND PARK , IL (CHICAGO)',
        size: '20,000',
        tenancy: 'Single Tenant',
    },
    {
        id: 3,
        img: '/assets/img/listing-property-01.png',
        price: '32,083,000',
        name: 'TESLA SALES, SERVICE & DELIVERY',
        address: 'ORLAND PARK , IL (CHICAGO)',
        size: '30,000',
        tenancy: 'Single Tenant',
    },
    {
        id: 4,
        img: '/assets/img/listing-property-02.png',
        price: '42,083,000',
        name: 'TESLA SALES, SERVICE & DELIVERY',
        address: 'ORLAND PARK , IL (CHICAGO)',
        size: '40,000',
        tenancy: 'Single Tenant',
    },
    {
        id: 5,
        img: '/assets/img/home-property-01.png',
        price: '52,083,000',
        name: 'TESLA SALES, SERVICE & DELIVERY',
        address: 'ORLAND PARK , IL (CHICAGO)',
        size: '50,000',
        tenancy: 'Single Tenant',
    },
    {
        id: 6,
        img: '/assets/img/home-property-02.png',
        price: '62,083,000',
        name: 'TESLA SALES, SERVICE & DELIVERY',
        address: 'ORLAND PARK , IL (CHICAGO)',
        size: '60,000',
        tenancy: 'Single Tenant',
    },
]



export default Arr