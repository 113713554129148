import React, { useEffect, useState } from 'react';
import Navbar from '../components/navbar';
import { Banner } from '../components/banner';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate, NavLink } from 'react-router-dom';

const TenantAdvisory = () => {
    const { status, token } = useSelector((state) => state.user);
    const navigate = useNavigate();

    if (!token) {
        navigate("/login")
    }


    window.scrollTo(0, 0);
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const OnViewCaseStudiesClick = () => {
        debugger
        localStorage.setItem("currentCase", "Tenant Advisory");
        navigate(token ? '/case-study' : '/register')
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASEURL}/api/tenant-advisory?populate=*`);
                console.log(response?.data);
                setData(response?.data?.data?.attributes);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error)
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    console.log(data, "<----datasss")

    return (
        <div>
            <Navbar />
            <Banner heading={"TenantAdvisory"} />

            <section className='container portfolioServices'>
                <div dangerouslySetInnerHTML={{ __html: data?.Heading1 }} />
                <div dangerouslySetInnerHTML={{ __html: data?.Paragraph1 }} />


                <div className='row'>
                    <div className='col-lg-4 col-md-6'>
                        <div className='portservBox text-center'>
                            <img src={`${process.env.REACT_APP_API_BASEURL}${data?.Box1Icon?.data?.attributes?.url}`} className='img-fluid' />
                            <h3>{data?.Box1Title}</h3>
                            <p>{data?.Box1Paragraph}</p>
                        </div>
                    </div>

                    <div className='col-lg-4 col-md-6'>
                        <div className='portservBox text-center'>
                            <img src={`${process.env.REACT_APP_API_BASEURL}${data?.Box2Icon?.data?.attributes?.url}`} className='img-fluid' />
                            <h3>{data?.Box2Title}</h3>
                            <p>{data?.Box2Paragraph}</p>
                        </div>
                    </div>

                    <div className='col-lg-4 col-md-6'>
                        <div className='portservBox text-center'>
                            <img src={`${process.env.REACT_APP_API_BASEURL}${data?.Box3Icon?.data?.attributes?.url}`} className='img-fluid' />
                            <h3>{data?.Box3Title}</h3>
                            <p>{data?.Box3Paragraph}</p>
                        </div>
                    </div>
                </div>
            </section>


            <section className='container-fluid tenantAdvisory'>
                <div className='d-flex justify-content-between flex-wrap'>
                    <div className='col-t'>
                        <img src={`${process.env.REACT_APP_API_BASEURL}${data?.Image1?.data?.attributes?.url}`} className='img-fluid' />
                    </div>
                    <div className='col-t'>
                        {/* <img src='/assets/img/tenant-watermark.png' className='img-fluid advisoryWmark'/>     */}
                        <img src={`${process.env.REACT_APP_API_BASEURL}${data?.Image2.data?.attributes?.url}`} className='img-fluid' />
                    </div>
                </div>
            </section>


            <section className='container-fluid suitSec'>
                <div dangerouslySetInnerHTML={{ __html: data?.Heading2 }} />

                <div className='d-flex justify-content-center flex-wrap'>
                    <div className='text-center'>
                        <img src={`${process.env.REACT_APP_API_BASEURL}${data?.Box4Icon?.data?.attributes?.url}`} className='img-fluid' />
                        <h6>{data?.Box4Title}</h6>
                    </div>

                    <div className='text-center mt-40'>
                        <img src={`${process.env.REACT_APP_API_BASEURL}${data?.Box5Icon?.data?.attributes?.url}`} className='img-fluid' />
                        <h6>{data?.Box5Title}</h6>
                    </div>

                    <div className='text-center'>
                        <img src={`${process.env.REACT_APP_API_BASEURL}${data?.Box6Icon?.data?.attributes?.url}`} className='img-fluid' />
                        <h6>{data?.Box6Title}</h6>
                    </div>

                    <div className='text-center mt-40'>
                        <img src={`${process.env.REACT_APP_API_BASEURL}${data?.Box7Icon?.data?.attributes?.url}`} className='img-fluid' />
                        <h6>{data?.Box7Title}</h6>
                    </div>

                    <div className='text-center'>
                        <img src={`${process.env.REACT_APP_API_BASEURL}${data?.Box8Icon?.data?.attributes?.url}`} className='img-fluid' />
                        <h6>{data?.Box8Title}</h6>
                    </div>
                </div>

                {/* <h2 className='processWmark'>Process</h2> */}

            </section>

            <section className='container-fluid leaseBackSec'>
                <div dangerouslySetInnerHTML={{ __html: data?.Heading3 }} />
                <div className='d-flex justify-content-center flex-wrap r1'>
                    <div className='text-center'>
                        <img src={`${process.env.REACT_APP_API_BASEURL}${data?.Box9Icon?.data?.attributes?.url}`} className='img-fluid' />
                        <h6>{data?.Box9Title}</h6>
                    </div>

                    <div className='text-center mt-40'>
                        <img src={`${process.env.REACT_APP_API_BASEURL}${data?.Box10Icon?.data?.attributes?.url}`} className='img-fluid' />
                        <h6>{data?.Box10Title}</h6>
                    </div>

                    <div className='text-center'>
                        <img src={`${process.env.REACT_APP_API_BASEURL}${data?.Box11Icon?.data?.attributes?.url}`} className='img-fluid' />
                        <h6>{data?.Box11Title}</h6>
                    </div>

                    <div className='text-center mt-40'>
                        <img src={`${process.env.REACT_APP_API_BASEURL}${data?.Box12Icon?.data?.attributes?.url}`} className='img-fluid' />
                        <h6>{data?.Box12Title}</h6>
                    </div>
                </div>
                <div className='d-flex justify-content-center flex-wrap r2'>
                    <div className='text-center'>
                        <img src={`${process.env.REACT_APP_API_BASEURL}${data?.Box13Icon?.data?.attributes?.url}`} className='img-fluid' />
                        <h6>{data?.Box13Title}</h6>
                    </div>
                    <div className='text-center'>
                        <img src={`${process.env.REACT_APP_API_BASEURL}${data?.Box14Icon?.data?.attributes?.url}`} className='img-fluid' />
                        <h6>{data?.Box14Title}</h6>
                    </div>
                    <div className='text-center'>
                        <img src={`${process.env.REACT_APP_API_BASEURL}${data?.Box15Icon?.data?.attributes?.url}`} className='img-fluid' />
                        <h6>{data?.Box15Title}</h6>
                    </div>
                    <div className='text-center'>
                        <img src={`${process.env.REACT_APP_API_BASEURL}${data?.Box16Icon?.data?.attributes?.url}`} className='img-fluid' />
                        <h6>{data?.Box16Title}</h6>
                    </div>
                </div>
                <div className='text-center mt-5'>
                    {<button onClick={OnViewCaseStudiesClick} className="btn btn-secondary mt-4 mb-4">Tenant Advisory Case Studies</button>}
                </div>
            </section>
        </div>
    );
}

export default TenantAdvisory;