import React, { useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../redux/features/user/userSlice';
import { ToastContainer, toast } from 'react-toastify';
import { SetActiveNav } from '../services/common';
import axios from 'axios';

const Navbar = () => {

  SetActiveNav()

  const dispatch = useDispatch();
  const { status, error, token } = useSelector((state) => state.user);

  const handlelogout = () => {
    dispatch(logoutUser());
  }


  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    currentPassword: '',
    password: '',
    passwordConfirmation: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const validateForm = () => {
    let errors = {};
    let isValid = true;


    if (!formData.currentPassword) {
      errors.currentPassword = 'Current Password is required';
      isValid = false;
    }

    if (!formData.password) {
      errors.password = 'Password is required';
      isValid = false;
    }

    if (!formData.passwordConfirmation) {
      errors.passwordConfirmation = 'Confirm Password is required';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setErrors({})

      try {

        const response = await axios.post(`${process.env.REACT_APP_API_BASEURL}/api/auth/change-password`, formData, {
          headers: {
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.statusText === "OK") {

          toast.success("Password changed successfully");
          setTimeout(function () {

            window.location.reload()
          }, 2000);
        }
      } catch (error) {


        toast.error(error.response.data.error.message);
      }
    }
  }

  // const location = useLocation();
  // const currentPageName = location.pathname;
  // debugger
  // let menu = document.getElementById(currentPageName.replace('/', ''))
  // if (menu) {
  //   menu.classList.add('activeNew')
  // }

  const [showCurrentPassword, setshowCurrentPassword] = useState(false);

  const toggleCurrentPasswordVisibility = () => {
    setshowCurrentPassword(!showCurrentPassword);
  };


  const [showPassword, setshowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setshowPassword(!showPassword);
  };


  const [showConfirmPassword, setshowConfirmPassword] = useState(false);

  const toggleConfirmPasswordVisibility = () => {
    setshowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
      <header>
        <nav className="navbar navbar-expand-xl header mainNav" id="mainNav">
          <div className="container-fluid">
            <NavLink to="/" className="logoBrand"><img src="/assets/img/footer-logo.png" alt="*" className="img-fluid" /></NavLink>
            <div className="offcanvas offcanvas-start-lg" tabindex="-1" id="offcanvasExample"
              aria-labelledby="offCanvasMenu">
              <div className="offcanvas-header d-flex d-xl-none">
                <h5 className="offcanvas-title" id="offCanvasMenu"><NavLink to="/" className="logoBrand"><img src="/assets/img/logo.svg" alt="*" className="img-fluid" /></NavLink></h5>
                <a href="javascript:void(0)" className="text-reset p-0" data-bs-dismiss="offcanvas"
                  aria-label="close">
                  <img src="/assets/img/icon-close.png" width="24" height="24" alt="close" />
                </a>
              </div>
              <div className="offcanvas-body justify-content-between">
                <ul className="navbar-nav mr-auto mb-2 mb-lg-0 mainMenu align-items-xl-center">
                  {/* <li className="nav-item dropdown">
                    <NavLink className="nav-link" id="navbarDropdown" to="/about-us">About</NavLink>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><NavLink className="dropdown-item" to="/team">Team</NavLink></li>
                    </ul>
                  </li> */}
                  <li className="nav-item">
                    <NavLink className={window.location.pathname == '/team' ? "nav-link activeNew" : "nav-link"} id='team' aria-current="page" to="/team">Team</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" id='listings' aria-current="page" to="/listings">Listings</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" id='exchange' aria-current="page" to="/exchange">1031 Exchanges</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" id={token ? "tenant-advisory" : "register1"} aria-current="page" to={token ? "/tenant-advisory" : "/register"} >Tenant Advisory</NavLink>
                  </li>
                  {/* to={token ? "/tenant-advisory" : "/login"} */}
                  {/* {token === null ? '' :
                    <>
                      <li className="nav-item">
                        <NavLink className="nav-link" aria-current="page" to="/tenant-advisory">Tenant Advisory</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" aria-current="page" to="/case-study">Case Studies</NavLink>
                      </li>
                    </>
                  } */}
                  <li className="nav-item">
                    <NavLink className="nav-link" id={token ? "case-study" : "register1"} aria-current="page" to={token ? "/case-study" : "/register"}>Case Studies</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className={window.location.pathname == '/contact' ? "nav-link activeNew" : "nav-link"} id='contact' aria-current="page" to="/contact">Contact</NavLink>
                  </li>
                </ul>
                <div className="d-flex align-items-center">


                  {token === null ? <><NavLink to="/login" className="btn">Sign In</NavLink><NavLink to="/register" className="btn btn-primary">Register</NavLink></>
                    :
                    <ul className="navbar-nav avatarDrop">
                      <li className="nav-item dropdown">
                        <img src='/assets/img/user-avatar.png' className='img-fluid avatar' />
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                          <li><a className="dropdown-item" href="#ModalChangePassword" data-bs-toggle="modal" >Change Password</a></li>
                          <li><button className="dropdown-item" onClick={handlelogout}><img src="/assets/img/logout-icon.png" className='img-fluid' /> Logout</button></li>
                        </ul>
                      </li>
                    </ul>}
                </div>
              </div>
            </div>
            <button className="btn navbar-toggler border-3 px-2" type="button" data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
              <img src="/assets/img/icon-menu.png" width="30" height="30" alt="menu icon" />
            </button>
          </div>
        </nav>
      </header>
      <div className="custom-modal modal fade" id="ModalChangePassword" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button type="button" className="btn-close m-0 ms-auto" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body forgetCont">
              <h2 className="sub-heading text-center">Change <span>Password</span></h2>
              <div className="row justify-content-center">
                <div className="col-lg-8 col-md-8 col-sm-9">

                  <form onSubmit={handleSubmit}>
                    <div className="form">
                      <div className="input-group">
                        <input
                          type={showCurrentPassword ? 'text' : 'password'}
                          placeholder='Current Password *'
                          className="form-control"
                          name="currentPassword"
                          value={formData.currentPassword}
                          onChange={handleChange} />

                        <div className="input-group-append">
                          <span onClick={toggleCurrentPasswordVisibility} className='eyeIcon'>

                            {showCurrentPassword ? (
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                              </svg>
                            ) : (
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                              </svg>
                            )}
                          </span>
                        </div>

                      </div>
                      {errors.currentPassword && <span className='text-danger'>{errors.currentPassword}</span>}
                      <div className="input-group">
                        <input
                          type={showPassword ? 'text' : 'password'}
                          placeholder='Password *'
                          className="form-control"
                          name="password"
                          value={formData.password}
                          onChange={handleChange} />


                        <div className="input-group-append">
                          <span onClick={togglePasswordVisibility} className='eyeIcon'>

                            {showPassword ? (
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                              </svg>
                            ) : (
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                              </svg>
                            )}
                          </span>
                        </div>
                      </div>
                      {errors.password && <span className='text-danger'>{errors.password}</span>}
                      <div className="input-group">
                        <input
                          type={showConfirmPassword ? 'text' : 'password'}
                          placeholder='Confirm Password *'
                          className="form-control"
                          name="passwordConfirmation"
                          value={formData.passwordConfirmation}
                          onChange={handleChange} />


                        <div className="input-group-append">
                          <span onClick={toggleConfirmPasswordVisibility} className='eyeIcon'>

                            {showConfirmPassword ? (
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                              </svg>
                            ) : (
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                              </svg>
                            )}
                          </span>
                        </div>
                        {errors.passwordConfirmation && <span className='text-danger'>{errors.passwordConfirmation}</span>}
                      </div>
                      <div className="submit-btn text-center input-group">
                        <button className="btn btn-primary w-100" type="submit" value="Submit" >Submit</button>
                      </div>
                    </div>
                  </form>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >

    </>
  )
}

export default Navbar;