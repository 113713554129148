import React from 'react'
import { useQuery, gql } from '@apollo/client';

const AboutData = gql`
query GetAbout{
    about{
      data{
        attributes{
          Title,
          Body,
          Heading1,
          Heading2,
          Heading3,
          SubHeading1,
          SubHeading2,
          SubHeading3,
          Capabilities1,
          Capabilities2,
          Capabilities3,
          Capabilities4,
          Capabilities5,
          Capabilities6,
          CapabilitiesDetails1,
          CapabilitiesDetails2,
          CapabilitiesDetails3,
          CapabilitiesDetails4,
          CapabilitiesDetails5,
          CapabilitiesDetails6,
          Image1{
            data{
              attributes{
                formats:url
              }
            }
          },
          CapabilitiesImage{
            data{
              attributes{
                formats:url
              }
            }
          }
        }
      }
    }
  }`


export const About = () => {


    const { loading, error, data } = useQuery(AboutData)

    if (loading) return <p>loading...</p>
    if (error) return <p>Error :::</p>

    return (
        <>
            {/* TOP SECTION */}
            <section className='container-fluid topSec'>
                <div className='container' >
                    <div dangerouslySetInnerHTML={{ __html: data?.about?.data?.attributes?.Title }}></div>
                    {/* <h2 className='mb-5 sub-heading'>Century Partners is a <span>real estate advisory firm</span> specializing in net leased investments nationwide.</h2> */}
                    <div dangerouslySetInnerHTML={{ __html: data?.about?.data?.attributes?.Body }}></div>
                </div>
                {/* <img src='/assets/img/home-top-watermark.svg' className='img-fluid' /> */}
            </section>

            {/* ABOUT SECION */}
            <section className='container-fluid aboutSec'>
                <div className='container'>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                        <div>
                            <img src={`${process.env.REACT_APP_API_BASEURL}${data?.about?.data?.attributes?.Image1?.data?.attributes?.formats}`} className='img-fluid w-100' />
                        </div>
                        <div>
                            <h3>{data?.about?.data?.attributes?.Heading1}</h3>
                            <p className='mb-5'>{data?.about?.data?.attributes?.SubHeading1}</p>
                            <h3>{data?.about?.data?.attributes?.Heading2}</h3>
                            <p className='mb-5'>{data?.about?.data?.attributes?.SubHeading2}</p>
                            <h3>{data?.about?.data?.attributes?.Heading3}</h3>
                            <p className='mb-5'>{data?.about?.data?.attributes?.SubHeading3}</p>

                        </div>
                    </div>
                </div>
                {/* <img src='/assets/img/home-about-watermark.png' className='img-fluid' /> */}
            </section>

            {/* ACCORDIAN SECTION */}
            <section className='container-fluid accordianSec'>
                <div className='row'>
                    <div className='col-md-6 my-auto'>
                        <div className="accordion accordion-flush" id="accordionFlushExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingOne">
                                    <button className="accordion-button collapsed" type="button">
                                        {data?.about?.data?.attributes?.Capabilities1}
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <p>{data?.about?.data?.attributes?.CapabilitiesDetails1}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingTwo">
                                    <button className="accordion-button collapsed" type="button">
                                        {data?.about?.data?.attributes?.Capabilities2}
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <p>{data?.about?.data?.attributes?.CapabilitiesDetails2}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingThree">
                                    <button className="accordion-button collapsed" type="button">
                                        {data?.about?.data?.attributes?.Capabilities3}
                                    </button>
                                </h2>
                                <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <p>{data?.about?.data?.attributes?.CapabilitiesDetails3}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingFour">
                                    <button className="accordion-button collapsed" type="button">
                                        {data?.about?.data?.attributes?.Capabilities4}
                                    </button>
                                </h2>
                                <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <p>{data?.about?.data?.attributes?.CapabilitiesDetails4}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingFive">
                                    <button className="accordion-button collapsed" type="button">
                                        {data?.about?.data?.attributes?.Capabilities5}
                                    </button>
                                </h2>
                                <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <p>{data?.about?.data?.attributes?.CapabilitiesDetails5}</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingSix">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                        {data?.about?.data?.attributes?.Capabilities6}
                                    </button>
                                </h2>
                                <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <p>{data?.about?.data?.attributes?.CapabilitiesDetails6}</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <img src={`${process.env.REACT_APP_API_BASEURL}${data?.about?.data?.attributes?.CapabilitiesImage?.data?.attributes?.formats}`} className='img-fluid w-100 h-100 object-fit-cover' />
                        <h2>Capabilities</h2>
                    </div>
                </div>
            </section>
        </>
    )
}
