import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { NavLink, useParams } from 'react-router-dom';

const allCaseStudies = gql`
query CaseStudy{
    caseStudies{
        data{
          id,
          attributes{
          title,
          Image{
              data{
                  attributes{
                      formats:url
                  }
              }
          },
          slug,
          subtitle,
          Outcomes,
          category
          }
        }
      }
  }
`

const AllcaseStudies = () => {

    
    const { loading, error, data } = useQuery(allCaseStudies);

    // const caseStudyData = data?.caseStudies?.data
    const { slug } = useParams()
    const caseStudyData = data?.caseStudies?.data.filter((x) => x.attributes.slug != slug).slice(0, 2)
    debugger
    console.log(data, "<----data")
    return (
        <div className='moreCase mt-5'>
            <h2 className="sub-heading mb-5 text-center">More <span>Case Studies</span></h2>
            {caseStudyData?.map((item, index) => {
                return (
                    <>
                        {index % 2 === 0 ? (

                            <div className="card mb-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className='position-relative h-100 w-100'>
                                            <img src={`${process.env.REACT_APP_API_BASEURL}${item?.attributes?.Image?.data?.attributes?.formats}`} className="img-fluid h-100 w-100 roundedImgLeft" />
                                            <h6>{item.attributes.category}</h6>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="card-body">
                                            <h3>{item.attributes.title}</h3>
                                            <h4>{item.attributes.subtitle}</h4>
                                            <ul>
                                                <div dangerouslySetInnerHTML={{ __html: item.attributes.list }}></div>
                                            </ul>
                                            <NavLink to={`/case-study-details/${item.attributes.slug}`} className="btn btn-secondary">View case study</NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (

                            <div className="card mb-5">

                                <div className="row flex-dir-rev">

                                    <div className="col-md-6">
                                        <div className="card-body">
                                            <h3>{item.attributes.title}</h3>
                                            <h4>{item.attributes.subtitle}</h4>
                                            <ul>
                                                <div dangerouslySetInnerHTML={{ __html: item.attributes.list }}></div>
                                            </ul>
                                            <NavLink to={`/case-study-details/${item.attributes.slug}`} className="btn btn-secondary">View case study</NavLink>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className='position-relative h-100 w-100'>
                                            <img src={`${process.env.REACT_APP_API_BASEURL}${item?.attributes?.Image?.data?.attributes?.formats}`} className="img-fluid h-100 w-100 roundedImgRight" />
                                            <h6>{item.attributes.category}</h6>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )

                        }
                    </>
                )
            })
            }
        </div>

    )
}

export default AllcaseStudies;