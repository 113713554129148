
export const GetData = () => {
    alert('dsfc')
}

export const SetActiveNav = () => {
    
    const location = window.location;
    const currentPageName = location.pathname;
    let menu = document.getElementById(currentPageName.replace('/', ''))
    if (menu) {
        menu.classList.add('activeNew')
    }
} 
