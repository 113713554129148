import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const singleListingTopSlider = ({ imgData }) => {

  const IsImgFile = (filePath) => {
    // Get the file extension
    const fileExtension = filePath.split('.').pop().toLowerCase();

    // Check if it's a video or image based on common extensions
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'tiff', 'ico', 'dvu'];
    if (imageExtensions.includes(fileExtension)) {
      return true;
    } else {
      return false;
    }
  }

  const settings = {
    centerMode: true,
    centerPadding: '17%',
    slidesToShow: 1,
    autoplay: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <div>
      <Slider {...settings}>

        {imgData?.map((data, index) => {
          return (
            <div className='focus-hide'>



              {!IsImgFile(data?.attributes?.formats) ?
                <>

                  <video className="img-fluid w-100" controls>
                    <source src={`${process.env.REACT_APP_API_BASEURL}${data?.attributes?.formats}`} />
                    Your browser does not support the video tag.
                  </video>
                </>
                :
                <>
                  <img src={`${process.env.REACT_APP_API_BASEURL}${data?.attributes?.formats}`} className="img-fluid w-100" />
                </>

              }


            </div>

          )
        })
        }

        {/* Add more slides as needed */}
      </Slider>
    </div>
  );
};

export default singleListingTopSlider;
