import React, { useState, useEffect } from 'react';
import Navbar from '../components/navbar';
import { Banner } from '../components/banner';
import { useQuery, gql } from '@apollo/client';
import { NavLink, useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';


const caseStudy = gql`
query CaseStudy{
    caseStudies (pagination: {limit:-1}){
      data{
        id,
        attributes{
        title,
        Image{
            data{
                attributes{
                    formats:url
                }
            }
        },
        slug,
        subtitle,
        Outcomes,
        category
        }
      }
    }
  }
`
const SingleCaseStudy = gql`
query caseStudy($categoryFilter: String){
	caseStudies(filters: { category: {eq: $categoryFilter} }) {
    data{
        id,
      attributes{
        title,
        Image{
            data{
                attributes{
                    formats:url
                }
            }
        },
        slug,
        subtitle,
        Outcomes,
        category
      }
    }
  }
}
`


const CaseStudy = () => {
    const { status, token } = useSelector((state) => state.user);
    const navigate = useNavigate();

    if (!token) {
        navigate("/login")
    }

    const [category, setCategory] = useState('All');

    const handleTabClick = (tabName) => {
        setCategory(tabName);
    };

    const { loading, error, data } = useQuery(category !== "All" ? SingleCaseStudy : caseStudy, {
        variables: {
            categoryFilter: category,
        },
    });
    const initialCaseStudyData = data?.caseStudies?.data.slice(0, 2).reverse()
    const [caseStudyData, setCaseStudyData] = useState(initialCaseStudyData)
    const [showButton, setButton] = useState(true);

    useEffect(() => {
        setCaseStudyData(data?.caseStudies?.data?.slice().sort((a, b) => parseInt(a.id) - parseInt(b.id)).reverse());
    }, [data]);

    useEffect(() => {
        const currentCaseStudy = localStorage.getItem("currentCase")
        if (currentCaseStudy === "Exchange") {
            setCategory(currentCaseStudy)
            localStorage.removeItem("currentCase");
        } else if (currentCaseStudy === "Tenant Advisory") {
            setCategory(currentCaseStudy)
            localStorage.removeItem("currentCase");
        }
    }, [])

    const handleViewAll = () => {
        setCaseStudyData(data?.caseStudies?.data);
        setButton(false)
    }


    if (error) {
        return <div>Error: {error?.message}</div>;
    }


    return (
        <div>
            <Navbar />
            <Banner heading={"CaseStudies"} />


            <section className='container caseStudyTabs' >
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link ${category === 'All' ? 'active' : ''}`}

                            aria-selected={category === 'All'}
                            onClick={() => handleTabClick('All')}
                        >
                            All
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        {/* <button className="nav-link" id="1031-Exchange-tab" data-bs-toggle="tab" data-bs-target="#1031-Exchange" type="button" role="tab" aria-controls="1031-Exchange" aria-selected="false">1031 Exchange</button> */}
                        <button
                            className={`nav-link ${category === 'Exchange' ? 'active' : ''}`}
                            aria-selected={category === 'Exchange'}
                            onClick={() => handleTabClick('Exchange')}
                        >
                            1031-Exchange
                        </button>

                    </li>

                    <li className="nav-item" role="presentation">
                        {/* <button className="nav-link" id="lease-tab" data-bs-toggle="tab" data-bs-target="#lease" type="button" role="tab" aria-controls="Lease" aria-selected="false">Lease</button> */}
                        <button
                            className={`nav-link ${category === 'Tenant Advisory' ? 'active' : ''}`}

                            aria-selected={category === 'Tenant Advisory'}
                            onClick={() => handleTabClick('Tenant Advisory')}
                        >
                            Tenant Advisory
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        {/* <button className="nav-link" id="sale-tab" data-bs-toggle="tab" data-bs-target="#sale" type="button" role="tab" aria-controls="Sale" aria-selected="false">Sale</button> */}
                        <button
                            className={`nav-link ${category === 'Sale Leaseback' ? 'active' : ''}`}

                            aria-selected={category === 'Sale Leaseback'}
                            onClick={() => handleTabClick('Sale Leaseback')}
                        >
                            Sale-Leaseback
                        </button>
                    </li>

                </ul>
                <div className="tab-content" id="myTabContent">
                    {/* All */}
                    <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">


                        {caseStudyData?.map((item, index) => {
                            return (
                                <>
                                    {index % 2 === 0 ? (

                                        <div className="card mb-5">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className='position-relative h-100 w-100'>
                                                        <img src={`${process.env.REACT_APP_API_BASEURL}${item?.attributes?.Image?.data?.attributes?.formats}`} className="img-fluid h-100 w-100" />
                                                        <h6>{item.attributes.category.replace('_', '-')}</h6>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card-body">
                                                        <h3>{item.attributes.title}</h3>
                                                        <h4>{item.attributes.subtitle}</h4>
                                                        <br />
                                                        <div dangerouslySetInnerHTML={{ __html: item.attributes.Outcomes }}></div>
                                                        <NavLink to={`/case-study-details/${item.attributes.slug}`} className="btn btn-secondary">View case study</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (

                                        <div className="card mb-5">

                                            <div className="row flex-dir-rev">

                                                <div className="col-md-6">
                                                    <div className="card-body">
                                                        <h3>{item.attributes.title}</h3>
                                                        <h4>{item.attributes.subtitle}</h4>
                                                        <br />
                                                        <div dangerouslySetInnerHTML={{ __html: item.attributes.Outcomes }}></div>
                                                        <NavLink to={`/case-study-details/${item.attributes.slug}`} className="btn btn-secondary">View case study</NavLink>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className='position-relative h-100 w-100'>
                                                        <img src={`${process.env.REACT_APP_API_BASEURL}${item?.attributes?.Image?.data?.attributes?.formats}`} className="img-fluid h-100 w-100" />
                                                        <h6>{item.attributes.category.replace('_', '-')}</h6>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    )

                                    }
                                </>
                            )
                        })
                        }



                    </div>

                </div>

                {/* {showButton && <div className="text-center"><a onClick={handleViewAll} className="btn btn-secondary">View All Case Studies</a></div>} */}

            </section>

        </div>
    );
}

export default CaseStudy;