import React, {useEffect, useState} from 'react';
import Navbar from '../components/navbar';
import { Banner } from '../components/banner';
import { ListingCard } from '../components/listingCard';
import { useQuery, gql } from '@apollo/client';


const AllListings = gql`
query Listings{
    listings (pagination: {limit:-1}) {
      data{
        id
        attributes{
          MainImage{
            data{
              attributes{
                formats:url
              }
            }
          },
        price,
        Title,
        slug,
        Location,
        CapRate,
        NOI,
        Size,
        Tenancy,
        Index,
        LeaseTerm
        }
      }
    }
     }	
`

const Listings = () => {
    const {loading, error, data} = useQuery(AllListings);
    const initialData = data?.listings?.data?.slice(0,2);
    const [LisitngsData, setLisitngsData] = useState(initialData)
    debugger
    const [showButton, setButton] = useState(true);
    
    const handleViewAll = () =>{
        setLisitngsData(data?.listings?.data);
        setButton(false)
    }

    useEffect(()=>{
        // setLisitngsData(data?.listings?.data?.slice(0,6))
        setLisitngsData(data?.listings?.data.slice().sort((a, b) => parseInt(a.attributes.Index) - parseInt(b.attributes.Index)))
        
    },[data])

    return (
        <div>
            <Navbar />
            <Banner heading={"Listings"} />
            <>
                {/* LISTINGS SECTION */}
                <section className='listings'>
                    <div className='container'>
                        <div className='d-flex justify-content-between align-items-center flex-wrap w-100'>

                            {
                                LisitngsData?.map((item, i) => {
                                    debugger
                                    return (
                                        <>
                                            <ListingCard data={item.attributes} id={item.id} key={i} />
                                        </>
                                    )
                                })
                            }
                        </div>

                        {/* <div className='text-center mt-5'>
                            {showButton && <a onClick={handleViewAll} className="btn btn-secondary mt-4" type="submit">View All Lisitngs</a>}
                        </div> */}
                    </div>
                </section>
            </>
        </div>
    );
}

export default Listings;