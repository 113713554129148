import React from 'react';
import { useNavigate, NavLink } from "react-router-dom";
import { useQuery, gql } from '@apollo/client';
import { useSelector } from 'react-redux';

const footer = gql
    `query Footer{
    footer{
      data{
        attributes{
          Heading,
          Paragraph
        }
      }
    }
  }`

const ContactDetail = gql`
query Teams{
    contact{
      data{
        attributes{
          Address,
          Phone,
          Email,
          Linkedin,
          Instagram,
          Linktree,
          LIC,
        }
      }
    }
}
`



const Footer = () => {
    const navigate = useNavigate();
    const { token } = useSelector((state) => state.user);

    const { loading, error, data } = useQuery(footer);
    console.log(data, "<----footer")
    const content = data?.footer?.data?.attributes

    const { loading: Loading, error: Error, data: Data } = useQuery(ContactDetail);
    const ContactDetails = Data?.contact?.data?.attributes

    const OnViewCaseStudiesClick = (e) => {
        debugger
        e.preventDefault()
        navigate(token ? '/case-study' : '/register')
        window.scrollTo(0, 0);
    }

    return (
        <>
            <footer>
                <div className="container">
                    <div className="d-flex flex-wrap justify-content-between align-items-start">
                        <div>
                            <h3>CENTURY PARTNERS</h3>
                            <p className='text-lightGrey'>{ContactDetails?.Address}</p>
                            <div className="footIcon">
                                {!ContactDetails?.Phone ? '' :
                                    <>
                                        <a href={'tel:' + ContactDetails?.Phone}><img src="/assets/img/phone.png" className="img-fluid" /></a>
                                    </>
                                }
                                {!ContactDetails?.Email ? '' :
                                    <>
                                        <a href={'mailto:' + ContactDetails?.Email}><img src="/assets/img/emailalt.png" className="img-fluid" /></a>
                                    </>
                                }
                                {!ContactDetails?.Linkedin ? '' :
                                    <>
                                        <a href={ContactDetails?.Linkedin}><img src="/assets/img/linkedin.png" className="img-fluid" /></a>
                                    </>
                                }
                                {/* <a href={ContactDetails?.Instagram}><img src="/assets/img/instagram-filled.png" className="img-fluid" /></a> */}
                                {!ContactDetails?.Linktree ? '' :
                                    <>
                                        <a href={ContactDetails?.Linktree}><img src="/assets/img/linktree_logo_icon_247832.png" className="img-fluid" /></a>
                                    </>
                                }
                            </div>
                        </div>

                        <div>
                            <ul>
                                {!ContactDetails?.Phone ? '' :
                                    <>
                                        <li><a href={'tel:' + ContactDetails?.Phone}>{ContactDetails?.Phone}</a></li>
                                    </>
                                }
                                {!ContactDetails?.Email ? '' :
                                    <>
                                        <li><a href={'mailto:' + ContactDetails?.Email}>{ContactDetails?.Email}</a></li>
                                    </>
                                }
                            </ul>
                        </div>

                        <div>
                            <ul>
                                {/* <li><NavLink to="/about-us">About</NavLink></li> */}
                                <li><NavLink to="/listings">Listings</NavLink></li>
                                <li><NavLink to="/exchange">1031 Exchange</NavLink></li>
                                <li><NavLink onClick={OnViewCaseStudiesClick}>Case Studies</NavLink></li>
                                <li><NavLink to="/contact">Contact Us</NavLink></li>
                            </ul>
                        </div>

                        {/* <div>
                            <ul>
                                <li><NavLink to="/">Terms of Use</NavLink></li>
                                <li><NavLink to="/">Privacy Policy</NavLink></li>
                                <li><NavLink to="/contact">Contact</NavLink></li>
                            </ul>
                        </div> */}

                        <div>
                            <img src="/assets/img/footer-logo.png" className="img-fluid" />
                        </div>
                    </div>

                    <div className="footerContent">
                        <p className="text-lightGrey mb-2">©{content?.Heading}</p>
                        <p className="smallTxt" dangerouslySetInnerHTML={{ __html: content?.Paragraph }}></p>
                    </div>
                </div>
            </footer >
        </>
    )
}

export default Footer;