import React, { useState, useEffect } from 'react';
import Navbar from '../components/navbar';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser, clearUser } from '../redux/features/user/userSlice';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import PasswordEye from '../components/passwordEye';

const ResetPassword = () => {
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [btnLoading, setLoading] = useState(false)


    const [formData, setFormData] = useState({
        code: '',
        password: '',
        passwordConfirmation: ''
    });

    const { status, error, token } = useSelector((state) => state.user);

    useEffect(() => {


        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        formData.code = code
        if (!code) {
            navigate("/")
        }
    }, [])


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const validateForm = () => {
        let errors = {};
        let isValid = true;


        if (!formData.password) {
            errors.password = 'Password is required';
            isValid = false;
        }

        if (!formData.passwordConfirmation) {
            errors.passwordConfirmation = 'Confirm Password is required';
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)

        if (validateForm()) {
            setErrors({})

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_BASEURL}/api/auth/reset-password`, formData);

                if (response.statusText === "OK") {

                    toast.success("Password changed successfully");
                    setLoading(false)

                    navigate("/login")
                }
            } catch (error) {

                toast.error(error.response.data.error.message);
            }
        }
        setLoading(false)
    }

    return (
        <div>

            <div className='secondary-header'>
                <Navbar />
            </div>

            <section className="container-fluid loginSec">
                <div className="row">
                    <div className="col-md-6">
                        <h2 className="sub-heading">Reset <span>Password</span></h2>
                        <hr></hr>
                        <form onSubmit={handleSubmit}>
                            <div className="form">
                                <div className="input-group">
                                    {/* <input
                                        type="password"
                                        placeholder='Password *'
                                        className="form-control"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange} />*/}
                                    <PasswordEye placeholder='Password *' name="password" value={formData.password} onChange={handleChange} />

                                </div>

                                {errors.password && <span className='text-danger'>{errors.password}</span>}

                                <div className="input-group">
                                    {/* <input
                                        type="password"
                                        placeholder='Confirm Password *'
                                        className="form-control"
                                        name="passwordConfirmation"
                                        value={formData.passwordConfirmation}
                                        onChange={handleChange} /> */}

                                    <PasswordEye placeholder='Confirm Password *' name="passwordConfirmation" value={formData.passwordConfirmation} onChange={handleChange} />

                                </div>
                                {errors.passwordConfirmation && <span className='text-danger'>{errors.passwordConfirmation}</span>}

                                <div className="input-group">
                                    <button type="submit" className="btn btn-primary w-100" >
                                        {btnLoading ? <div className="spinner-border text-light" role="status">
                                        </div> : "Submit"
                                        }
                                    </button>

                                </div>
                                {error && <span className='text-danger'>{error}</span>}
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 text-end">
                        <img src="/assets/img/login-img.jpg" className="img-fluid w-100 h-100 object-fit-cover" />
                    </div>
                </div>
            </section>

        </div >
    );
}

export default ResetPassword;