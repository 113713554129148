// userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  user: null,
  token: null, // Add token field
  status: 'idle',
  error: null,
};

export const registerUser = createAsyncThunk('user/registerUser', async (userData) => {
  try {
    debugger
    const response = await axios.post(`${process.env.REACT_APP_API_BASEURL}/api/auth/local`, userData);
    if (response.data.user.Active === false) {
      throw 'You are not activated yet. Contact cp@centurypartnersre.com if you are experiencing login issues.'
    }
    return response;
  } catch (error) {
    if (error === 'You are not activated yet. Contact cp@centurypartnersre.com if you are experiencing login issues.') {
      throw error
    }
    else {
      debugger
      if(error.response.data.error.message =='Invalid identifier or password'){
        throw 'Invalid email or password. Contact cp@centurypartnersre.com if you are experiencing login issues.'
      }else{
        throw error.response.data.error.message + '  Contact cp@centurypartnersre.com if you are experiencing login issues.'

      }
    }
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    clearToken: (state) => {
      state.token = null;
    },
    logoutUser: (state) => {
      state.user = null;
      state.token = null;
      state.status = 'idle';
      state.error = null;
    },


  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload.data.user;
        state.token = action.payload.data.jwt
        state.error = null
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(logoutUser, (state) => {
        state.user = null;
        state.token = null;
        state.status = 'idle';
        state.error = null;
      });
  },
});

export const { setToken, clearToken, logoutUser } = userSlice.actions;

export default userSlice.reducer;
