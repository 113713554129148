import React, { useState, useEffect } from 'react';
import Navbar from '../components/navbar';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser, clearUser } from '../redux/features/user/userSlice';
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useQuery, gql } from '@apollo/client';
import axios from 'axios';
import PasswordEye from '../components/passwordEye';


const bannerData = gql`
    query Banner{
        banner{
            data{
                attributes{
                    Login{
                        data{
                            attributes{
                                formats:url
                            }
                        }
                    },
                }
            }
        }
    }`

const Login = () => {

  const { loading: BannerLoading, error: Error, data } = useQuery(bannerData);
  const ImageUrl = data?.banner?.data?.attributes?.Login?.data?.attributes?.formats

  console.log(data?.banner?.data?.attributes?.Signup?.data?.attributes?.formats, "<----BANNER Login")


  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    identifier: '',
    password: ''
  });

  const [btnLoading, setLoading] = useState(false)

  const { status, error, token } = useSelector((state) => state.user);

  useEffect(() => {
    if (token) {
      navigate("/")
    }
  }, [token])

  const displaySuccessNotification = (msg) => {
    toast.success(msg);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!formData.identifier) {
      errors.identifier = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.identifier)) {
      errors.identifier = 'Invalid email address';
      isValid = false;
    }

    if (!formData.password) {
      errors.password = 'Password is required';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      debugger
      dispatch(registerUser(formData))
      setErrors({})
      setFormData({ identifier: '', password: '', })
    }
  }


  const [ForgotPasswordformData, setForgotPasswordFormData] = useState({

    email: ''
  });

  const handleForgotPasswordChange = (e) => {
    const { name, value } = e.target;
    setForgotPasswordFormData({
      ...ForgotPasswordformData,
      [name]: value,
    });
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true)
      const response = await axios.post(`${process.env.REACT_APP_API_BASEURL}/api/auth/forgot-password`, ForgotPasswordformData);

      if (response.statusText === "OK") {

        toast.success("Please check your email.");
        setTimeout(function () {
          window.location.reload()
          setLoading(false)
        }, 2000);
      }
    } catch (error) {

      toast.error('' + error.response.data.error.message);
    }

  }
  return (
    <div>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />

      <div className='secondary-header'>
        <Navbar />
      </div>

      <section className="container-fluid loginSec">
        <div className="row">
          <div className="col-md-6">
            <h2 className="sub-heading">Welcome <span>Back</span></h2>
            <hr></hr>
            <form onSubmit={handleSubmit}>
              <div className="form">
                <div className="input-group">
                  <input
                    type="text"
                    placeholder='Email *'
                    className="form-control"
                    name="identifier"
                    value={formData.identifier}
                    onChange={handleChange} />
                </div>
                {errors.identifier && <span className='text-danger'>{errors.identifier}</span>}

                <div className="input-group">
                  {/* <input
                    type="password"
                    placeholder='Password *'
                    className="form-control"
                    name="password"
                    value={formData.password}
                    onChange={handleChange} /> */}

                  <PasswordEye placeholder='Password *' name="password" value={formData.password} onChange={handleChange}/>
                </div>
                {errors.password && <span className='text-danger'>{errors.password}</span>}

                <p className='mt-2 mb-0'><a href="#ModalForgotPassword" data-bs-toggle="modal" className='text-blue px-0'>Forgot Password?</a></p>

                <div className="input-group">
                  <button type="submit" className="btn btn-primary w-100" value="Login Now">

                    {status === "loading" ? <div class="spinner-border text-light" role="status">
                    </div> : "Login Now"
                    }
                  </button>
                </div>
                {error && <p className='text-danger'>{error}</p>}

                <p>Don't you have an account?
                  <NavLink to="/register">Sign Up</NavLink>
                </p>
              </div>
            </form>
          </div>
          <div className="col-md-6 text-end">
            {BannerLoading ? <p>Loading</p> :
              <img src={`${process.env.REACT_APP_API_BASEURL}${ImageUrl}`} className="img-fluid w-100 h-100 object-fit-cover" />}</div>
        </div>
      </section>


      {/* <!-- Modal Forget Password --> */}
      <div className="custom-modal modal fade" id="ModalForgotPassword" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button type="button" className="btn-close m-0 ms-auto" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body forgetCont">
              <h2 className="sub-heading text-center">Forget <span>Password</span></h2>
              <div className="row justify-content-center">
                <div className="col-lg-8 col-md-8 col-sm-9">

                  <form onSubmit={handleForgotPasswordSubmit}>
                    <div className="form">
                      <div className="input-group">
                        <input
                          type="text"
                          placeholder='Email *'
                          className="form-control"
                          name="email"
                          required
                          value={formData.email}
                          onChange={handleForgotPasswordChange} />
                      </div>
                    </div>
                    <div className="submit-btn text-center input-group">
                      <button className="btn btn-primary w-100" type="submit" disabled={btnLoading} value="Submit" >
                        {btnLoading ? <div class="spinner-border text-light" role="status">
                        </div> : "Submit"
                        }
                      </button>

                    </div>
                  </form>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >



    </div >
  );
}

export default Login;