import React from 'react';
import Navbar from '../components/navbar';
import { Banner } from '../components/banner';
import { Teams } from '../components/teams';

const Team = () => {
    return (
        <div>
            <Navbar />
            <Banner heading={"Teams"} img={'/assets/img/team-banner.jpg'} />

            {/* TEAM CARD SECTION */}
            <section className='container-fluid teamCardSec bg-white mt-0'>
                {/* <img src='/assets/img/about-team-watermark.png' className='img-fluid waterMark' /> */}
                <div className='container'>
                    <h2 className='sub-heading mb-5'>Century <span>Partners</span></h2>

                    <div className='d-flex justify-content-center flex-wrap w-100 mb-5'>

                        <Teams />

                    </div>


                </div>
            </section>

        </div>
    );
}

export default Team;