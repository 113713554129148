import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useQuery, gql } from '@apollo/client';


const Images = gql`
query CaseStudy{
  home{
    data{
      attributes{
        Banner{
          data{
            attributes{
              formats:url
            }
          }
          }
        }
      }
    }
  }
`

const SlickSlider = () => {
      const {loading, error, data} = useQuery(Images);
     
        const Data = data?.home?.data?.attributes?.Banner?.data


    const settings = {
        dots: true,
        infinite: true,
        fadeSpeed: 1000,
        autoplay: true,
        fade: true,
        infinite: true,
        arrows: false,
        autoplaySpeed: 3000,
        initialSlide: 1,
    };
  
    return (
      <div>
        <Slider {...settings}>

          {Data?.map((img, index)=>{
            return(
              <div>
                <img src={`${process.env.REACT_APP_API_BASEURL}${img?.attributes?.formats}`} className="img-fluid w-100" />
              </div>
            )
          })
          }
          {/* Add more slides as needed */}
        </Slider>
      </div>
    );
  };
  
  export default SlickSlider;
  